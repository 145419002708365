import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  // Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Tooltip,
} from "reactstrap";
import request from "../../../../utils/request";
import { translate, t } from "react-switch-lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { convertNumber } from "../../../../utils/formatter";
import langUtils from "../../../../utils/language/index";

function TopUpToken({ data, isOpen, isClose, priceList, hasUrl }) {
  const [hint, setHint] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const { values, isSubmitting, setValues, handleSubmit, ...formik } = useFormik({
    initialValues: {
      modelId: 0,
      expiredMonth: 0,
    },
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      let formTopUp = new FormData();
      formTopUp.append("model", "topup");
      formTopUp.append("modelId", values.modelId);
      request
        .post(`v1/invoice`, formTopUp)
        .then((res) => {
          toast.success(t("topupProcessed"));
          // setModalToken(false)
          hasUrl(res.data.url);
          isClose();
        })
        .catch((err) => {
          if (err.response?.status === 422) {
            setErrors(err.response.data.errors);
            return;
          }
          Promise.reject(err);
        })
        .finally(() => setSubmitting(false));
    },
  });

  const changePrice = function (e, price) {
    const value = parseInt(e.target.value);
    formik.setFieldValue("modelId", value);
    formik.setFieldValue("expiredMonth", price.expiredMonth);
    formik.setFieldTouched("modelId", true);
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpen} className="token-master">
        <ModalHeader className="border-bottom-0">
          {t("topupTokenQuota")}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between align-middle mb-3">
            <div className="mt-2">{t("chooseQuota")}</div>
            <Button
              onClick={() => setHint(!hint)}
              className="text-nowrap"
              style={{
                backgroundColor: "transparent",
                border: "transparent",
              }}
              id="TooltipExample"
            >
              <i className="fa fa-lg fa-question-circle text-primary" />
            </Button>
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target="TooltipExample"
              toggle={toggleTooltip}
            >
              {t("tooltipQuotaToken")}
              <br />
            </Tooltip>
          </div>
          <Row className="mt-3">
            {priceList?.map((price, idx) => (
              <Col key={idx} md="6" lg="4">
                <Label className="my-1">
                  <Input
                    type="radio"
                    className="d-none"
                    value={price.id}
                    checked={values.modelId === price.id}
                    onChange={(e) => changePrice(e, price)}
                  />
                  <div className="card bg-light d-flex flex-column">
                    <div className="card-header">
                      <b>{price.name}</b>
                    </div>
                    <div className="card-body">
                      <FontAwesomeIcon icon="coins" className="mr-1" />{" "}
                      {price.balance} token
                      <br />
                      <i className="fa fa-users mr-1" /> IDR{" "}
                      {convertNumber(price.price, "0,0")}
                      <br />
                      <i className="fa fa-calendar-times-o mr-1" />{" "}
                      {price.expiredMonth}&nbsp;{t('bulan')}
                    </div>
                  </div>
                </Label>
              </Col>
            ))}
          </Row>
          <div className="mt-3 text-left">
            <i className="fa fa-exclamation-triangle mr-1 text-warning" />
            <i>
              {t("tokenValidUntil")}
              <br />
              {moment(data.expired_at)
                .locale(langUtils.getLanguage())
                .format("DD MMMM YYYY LT")}
            </i>
          </div>
        </ModalBody>
        <ModalFooter className="border-top-0">
          <Button
            onClick={() => {
              formik.setFieldValue("modelId", null);
              formik.setFieldTouched("modelId", false);
              // toggle()
              isClose();
            }}
            color="netis-danger"
          >
            <i className="fa fa-times mr-1" /> {t("cancel")}
          </Button>
          <Button
            color="netis-color"
            className="mr-1"
            disabled={!values.modelId || isSubmitting}
            onClick={() => {
              if (values.expiredMonth < moment(data.expired_at).diff(moment(new Date()), 'months')) {
                setModalWarning(true)
              }
              else {
                handleSubmit()
              }
            }}
          >
            {isSubmitting ? (
              <>
                <Spinner size="sm" /> {t("wait")}
              </>
            ) : (
              <>
                <i className="fa fa-users mr-1" />
                {t("buy")}
              </>
            )}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalWarning}>
        <ModalBody>
          <div>Masa aktif paket ini lebih pendek dari masa aktif token Anda, Kami akan menggunakan masa aktif terlama. Apakah dilanjutkan?</div>
        </ModalBody>
        <ModalFooter className="border-top-0">
          <Button
            onClick={() => setModalWarning(false)}
            color="netis-danger"
          >
            <i className="fa fa-times mr-1" /> {t("cancel")}
          </Button>
          <Button
            color="netis-color"
            className="mr-1"
            disabled={!values.modelId || isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <>
                <Spinner size="sm" /> {t("wait")}
              </>
            ) : (
              <>
                <i className="fa fa-users mr-1" />
                {t("buy")}
              </>
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default translate(TopUpToken);
