import React, { useState, useEffect } from "react";
import { Spinner, Modal, ModalBody } from "reactstrap";
import { memo } from "react";
import { useBalance } from "../../../../hooks/useBalance";
import { useMemo } from "react";
import { t } from "react-switch-lang";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertNumber } from "../../../../utils/formatter";
import { useInvoice } from "../../../../hooks/useInvoice";
import langUtils from "../../../../utils/language/index";
import moment from "moment";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import { logout } from "../../../../actions/auth";
import { Link } from "react-router-dom";
function Countdown({ user, className, logout, useShowWhileScroll = true }) {
  const { loading, data } = useBalance();

  const { data: invoice, loading: loadingInvoice } = useInvoice([]);
  const expiredAt = useMemo(() => data.expired_at, [data]);
  const myBalance = useMemo(() => data?.balance ?? 0, [data]);
  const isExpired = useMemo(() => data?.isExpired, [data]);
  const isInvoice = useMemo(() => data?.invoice, [data]);
  const type = useMemo(() => data?.token_type, [data]);
  const countInvoice = useMemo(() => data?.countInvoice, [data]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const pendingData = useMemo(() => {
    const pending = invoice?.filter((data) => {
      return data.status === "pending";
    });
    return pending;
  }, [invoice]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft);
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const calculateTimeLeft = () => {
    let difference = +new Date(expiredAt) - +new Date();

    if (difference > 0) {
      setTimeLeft({
        hari: Math.floor(difference / (1000 * 60 * 60 * 24)),
        jam: Math.floor((difference / (1000 * 60 * 60)) % 24),
        menit: Math.floor((difference / 1000 / 60) % 60),
        detik: Math.floor((difference / 1000) % 60),
      });
    }
  };
  return (
    <>
      {user.personnel.company.paid === "post" ? (
        isInvoice ? (
          countInvoice >= 2 ? (
            <Modal isOpen={true}>
              <ModalBody className="text-center">
                <h3>
                  <b>{t("accessBlock")}</b>
                </h3>
                <br />
                <br />
                <img
                  src={require("../../../../assets/img/ad.png")}
                  width="200px"
                  alt="blockir"
                />
                <br />
                <br />
                <p>{t("hasLunas")}</p>
                <br />
                {loadingInvoice ? (
                  <Spinner />
                ) : pendingData.length !== 0 ? (
                  <InvoicesList data={pendingData} user={user} />
                ) : null}

                <button className="btn btn-netis mt-3" onClick={logout}>
                  <i className="fa fa-lock mr-2"></i>Logout
                </button>
              </ModalBody>
            </Modal>
          ) : new Date() > new Date(pendingData[0]?.expiredAt) ? (
            <Modal isOpen={true}>
              <ModalBody className="text-center">
                <h3>
                  <b>{t("accessBlock")}</b>
                </h3>
                <br />
                <br />
                <img
                  src={require("../../../../assets/img/ad.png")}
                  width="200px"
                  alt="blockir"
                />
                <br />
                <br />
                <p>{t("hasLunas")}</p>
                <br />
                {loadingInvoice ? (
                  <Spinner />
                ) : pendingData.length !== 0 ? (
                  <InvoicesList data={pendingData} user={user} />
                ) : null}

                <button className="btn btn-netis mt-3" onClick={logout}>
                  <i className="fa fa-lock mr-2"></i>Logout
                </button>
              </ModalBody>
            </Modal>
          ) : (
            <div
              className={`recruitment-header ${className} ${
                scrolling ? "hide" : "show"
              }`}
            >
              <Link to="/invoices">
                <div
                  className={`recruitment-item ${
                    timeLeft && timeLeft?.hari ? "long" : "short"
                  } `}
                >
                  <div className="my-auto" disabled={true}>
                    {loading ? (
                      <Spinner size="sm" color="primary" className="my-auto" />
                    ) : (
                      <>
                        <TimerDisplay
                          interval={timeLeft}
                          type={type}
                          isPostPaid={user.personnel.company.paid}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          )
        ) : (
          ""
        )
      ) : timeLeft?.hari < 30 && (myBalance || !isExpired) ? (
        <Link to="/invoices">
          <div
            className={`recruitment-header ${className} ${
              scrolling ? "hide" : "show"
            }`}
          >
            <div
              className={`recruitment-item ${
                timeLeft && timeLeft?.hari ? "long" : "short"
              } `}
            >
              <div className="my-auto" disabled={true}>
                {loading ? (
                  <Spinner size="sm" color="primary" className="my-auto" />
                ) : (
                  <>
                    <TimerDisplay interval={timeLeft} type={type} />
                  </>
                )}
              </div>
            </div>
          </div>
        </Link>
      ) : (
        ""
      )}
    </>
  );
}

const InvoicesList = ({ data, user }) => {
  return (
    data &&
    data?.map((item) => {
      return (
        <div
          key={item.id}
          className="card-link-invoice"
          onClick={() => window.open(item.url)}
        >
          <Card style={{ borderRadius: "12px" }}>
            <CardHeader
              style={{ backgroundColor: "#fff" }}
              className="d-flex justify-content-between"
            >
              <div>
                {"Tagihan Bulan " +
                  moment(item.expiredAt)
                    .subtract(1, "months")
                    .format("MMMM YYYY")}
              </div>
              <div>
                {item.status === "pending" ? (
                  <small className="text-muted">
                    {t("payBefore")}{" "}
                    {moment(item.expiredAt)
                      .locale(langUtils.getLanguage())
                      .format("DD MMMM YYYY LT")}
                  </small>
                ) : null}
              </div>
            </CardHeader>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="mr-4 font-xl dropdown-token">
                  <FontAwesomeIcon
                    icon="coins"
                    className="mx-auto"
                    style={{ color: "rgba(243, 216, 50, 1)" }}
                  />
                </div>
                <div className="flex-fill small">
                  <div className="d-flex">
                    <div>
                      <span style={{ fontSize: 15 }} className="ml-auto">
                        <FontAwesomeIcon
                          icon="coins"
                          color="#f3d832"
                          className="mr-1"
                        />{" "}
                        {item.balance} token
                      </span>
                      <br />
                      <span style={{ fontSize: 14 }} className="mr-auto">
                        <FontAwesomeIcon
                          icon="money-bill-wave"
                          color="#137500"
                          className="mr-1"
                        />{" "}
                        {convertNumber(item.amount, "$ 0,0[.]00")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      );
    })
  );
};

const TimerDisplay = memo(({ interval, type, isPostPaid }) => {
  const handleDisplay = () => {
    if (interval?.hari <= 30 && interval?.hari > 0) {
      return (
        <>
          <span className="mr-1">
            {interval?.hari ?? 0} {t("hari")}
          </span>
        </>
      );
    } else if (interval?.hari === 0 && interval?.jam > 0) {
      return (
        <>
          <span className="mr-1">
            {interval?.jam ?? 0} {t("jam")}
          </span>
        </>
      );
    } else if (interval?.hari === 0 && interval?.jam === 0) {
      return (
        <>
          <span className="mr-1">
            {interval?.menit ?? 0} {t("minute")}
          </span>
        </>
      );
    } else if (
      interval?.hari === 0 &&
      interval?.jam === 0 &&
      interval?.menit === 0
    ) {
      return (
        <>
          <span className="mr-1">
            {interval?.detik ?? 0} {t("second")}
          </span>
        </>
      );
    }
  };
  return (
    <div>
      {!interval ? (
        <Spinner size="sm" color="primary" className="my-auto" />
      ) : (
        <>
          {isPostPaid === "post"
            ? t("bayarTagihanSebelum")
            : type === "trial"
            ? t("trialWillEnd")
            : t("tokenExpire")}{" "}
          {handleDisplay()}
        </>
      )}
    </div>
  );
});

const mapStateToProps = ({ menu: panelMenu, user, token }) => ({
  panelMenu,
  user,
  token,
});
export default connect(mapStateToProps, { logout })(Countdown);
