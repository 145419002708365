import React, { useEffect, useState } from "react";
import PageLayout from "../../Pages/Layout/PageLayout";
import { Container } from "reactstrap";
import {
  Accordion,
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItem,
} from "react-accessible-accordion";
import WhatsappButton from "../WhatsappButton";

function FAQ(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [isOpen, setIsOpen] = useState(null);
  const data = [
    {
      id: 1,
      question: "Apa itu Aikrut.id",
      answer: (
        <>
          Aikrut adalah platform yang mempermudah dan mengoptimalkan secara
          singkat proses rekrutmen perusahaan. Bersama Aikrut, perusahaan dapat
          mengakses CV, hasil asesmen psikotes dan hasil wawancara online
          pelamar yang sudah otomatis terintegrasi dengan teknologi{" "}
          <i>Artificial Intelligence (AI)</i>.
        </>
      ),
    },
    {
      id: 2,
      question: "Apa itu token?",
      answer:
        "Token adalah kredit yang digunakan untuk membuka akses setiap fitur sesuai dengan kebutuhan perusahaan dan penggunaannya dikontrol oleh Team HR sehingga lebih efisien dan fleksibel.",
    },
    {
      id: 3,
      question: "Apakah Aikrut sama dengan Job Portal dan Talent Hunting?",
      answer: (
        <>
          Tidak, Aikrut berbeda dengan Job Portal dan Talent Hunting. Aikrut
          berperan dalam mempermudah proses rekrutmen dengan teknologi{" "}
          <i>Artificial Intelligence (AI)</i> sehingga Anda bisa mendapat data
          ilmiah kandidat secara tepat dimanapun dan kapanpun.
        </>
      ),
    },
    {
      id: 4,
      question: "Apa saja fitur yang tersedia di Aikrut?",
      answer: (
        <>
          Aikrut menghadirkan berbagai fitur terbaik dengan teknologi{" "}
          <i>Artificial Intelligence (AI)</i> dan asesmen online yang terdiri
          atas
          <i>
            {" "}
            Learning Agility Test, Entrepreneur Test, Aptitude Test, Work Style
            Test, Personality Test, Leadership Test, dan Cognitive Ability Test,
            Fisiognomi, Palmistry, Biometric Test,
          </i>{" "}
          dan tes pengukuran karakteristik lainnya.
        </>
      ),
    },
    {
      id: 5,
      question: "Apa saja yang bisa Aikrut bantu untuk perusahaan?",
      answer: (
        <>
          Dengan teknologi <i>Artificial Intelligence</i>, Aikrut membantu
          perusahaan menemukan kandidat yang selaras dengan nilai perusahaan
          Anda melalui asesmen yang dilakukan bersama Aikrut.
        </>
      ),
    },
    {
      id: 6,
      question: "Apa saja keuntungan perusahaan setelah menggunakan Aikrut?",
      answer: (
        <ul>
          <li>
            URL khusus sebagai platform rekrutmen yang dapat diakses oleh para
            pelamar
          </li>
          <li>Akun uji coba selama 30 hari untuk mencoba semua fitur Aikrut</li>
          <li>
            Fitur alat tes yang dapat dipilih oleh HR sesuai dengan kebutuhan
          </li>
          <li>
            Tanpa mobilitas dan kontak fisik, semua bisa dilakukan secara online
          </li>
        </ul>
      ),
    },
    {
      id: 7,
      question:
        "Bagaimana sistem kerjasama perusahaan sebagai pengguna Aikrut?",
      answer: (
        <>
          Aikrut bekerjasama dengan perusahaan mitra dengan skema{" "}
          <i>subscription</i> (berlangganan) yang mana perusahaan mitra hanya
          perlu melakukan sekali pembayaran di awal untuk mendapatkan Token yang
          nantinya digunakan untuk mengakses fitur-fitur yang tersedia di
          Aikrut.
        </>
      ),
    },
  ];

  const windowOnScroll = () => {
    if (window.scrollY > 0) {
      if (
        !document.getElementsByTagName("nav")[0].classList.contains("shadow-sm")
      ) {
        document.getElementsByTagName("nav")[0].classList.add("shadow-sm");
      }
    } else {
      if (
        document.getElementsByTagName("nav")[0].classList.contains("shadow-sm")
      ) {
        document.getElementsByTagName("nav")[0].classList.remove("shadow-sm");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", windowOnScroll);

    return () => {
      window.removeEventListener("scroll", windowOnScroll);
    };
  });

  return (
    <PageLayout footer={props.footer} navbarComp={props.navbarComp}>
      <section className="container mb-5" style={{ paddingTop: "10px" }}>
        <div className="assessment-header d-md-block text-center mb-md-5">
          <h3 style={{ fontWeight: "bold" }}>FAQ</h3>
          <br />
        </div>
        <Container>
          <Accordion>
            {data.map((data, idx) => (
              <AccordionItem
                className="faq-accordion-card shadow px-4 pt-3 pb-2"
                key={idx}
                onClick={() => {
                  setIsOpen(idx);
                }}
              >
                <AccordionItemHeading className="faq-accordion-heading mb-2">
                  <AccordionItemButton
                    id="faq-heading"
                    className="d-flex justify-content-between align-items-center flex-rows"
                  >
                    <h4 className="faq-title" style={{ fontWeight: "bold" }}>
                      {data.question}
                    </h4>
                    {isOpen === idx ? (
                      <i className="fa fa-minus" />
                    ) : (
                      <i className="fa fa-plus" />
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel
                  id="faq-body"
                  className="faq-accordion-body"
                >
                  <p style={{ fontSize: "16px" }}>{data.answer}</p>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Container>
      </section>

      <WhatsappButton />
    </PageLayout>
  );
}

export default FAQ;
