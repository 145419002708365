import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoWhite from "../../assets/img/newLandingPageV2/logo_aikrut_white.png";
import LogoBlue from "../../assets/img/newLandingPageV2/logo_aikrut_blue.png";

import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Collapse,
  Modal,
  Container,
} from "reactstrap";
import { translate, t } from "react-switch-lang";

function NavbarComp() {
  const location = useLocation();
  const currPosition = "home";
  const collapsed = true;
  const [openDrawer, setOpenDrawer] = useState(false);
  const isHome = location.pathname === "/";
  const isAssessment = location.pathname === "/assessment-inventory";
  const isFaq = location.pathname === "/faq";
  const isPricing = location.pathname === "/pricing";

  const [currLogo, setCurrLogo] = useState(LogoWhite);

  const toggleNavbar = () => setOpenDrawer(true);
  const closeDrawer = () => setOpenDrawer(false);

  const windowOnScroll = () => {
    if (window.scrollY > 0) {
      if (
        !document
          .getElementsByTagName("nav")[0]
          .classList.contains("shadow-sm", "navbarOnScroll")
      ) {
        document
          .getElementsByTagName("nav")[0]
          .classList.add("shadow-sm", "navbarOnScroll");
        setCurrLogo(LogoBlue);
        document.getElementsByTagName("i")[0].classList.remove("text-white");
        document.getElementsByTagName("i")[0].classList.add("text-black");
      }
    } else {
      if (
        document
          .getElementsByTagName("nav")[0]
          .classList.contains("shadow-sm", "navbarOnScroll")
      ) {
        document
          .getElementsByTagName("nav")[0]
          .classList.remove("shadow-sm", "navbarOnScroll");
        setCurrLogo(LogoWhite);
        document.getElementsByTagName("i")[0].classList.remove("text-black");
        document.getElementsByTagName("i")[0].classList.add("text-white");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", windowOnScroll);

    return () => {
      window.removeEventListener("scroll", windowOnScroll);
    };
  });

  const getNavItemClass = (pos) =>
    pos === currPosition ? "mr-3 active" : "mr-3";

  return (
    <>
      <Navbar
        className={
          isFaq
            ? "navbar-expand-md fixed-top custom-nav bg-white "
            : "navbar-expand-md fixed-top custom-nav"
        }
      >
        <Container>
          <NavbarBrand href="/" className="mr-auto">
            <img
              src={isFaq ? LogoBlue : currLogo}
              id="aikrut-logo"
              className="navbar-logo"
              alt="widya-skilloka"
            />
          </NavbarBrand>
          <div className="ml-auto d-flex">
            <Collapse isOpen={!collapsed} navbar>
              <Nav navbar>
                <NavItem
                  className={isHome ? "active-navbar" : ""}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <Link
                    className={isFaq ? "custom-nav text-dark" : "custom-nav"}
                    to="/"
                    disabled
                  >
                    {t("Beranda")}
                  </Link>
                </NavItem>
                <NavItem
                  className={isAssessment ? "active-navbar" : ""}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <Link
                    className={isFaq ? "custom-nav text-dark" : "custom-nav"}
                    to="/assessment-inventory"
                    disabled
                  >
                    {t("Fitur")}
                  </Link>
                </NavItem>
                <NavItem
                  className={isPricing ? "active-navbar" : ""}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <Link
                    className={isFaq ? "custom-nav text-dark" : "custom-nav"}
                    to="/pricing"
                    disabled
                  >
                    {t("Harga")}
                  </Link>
                </NavItem>
                <NavItem className={getNavItemClass("article")}>
                  <Link
                    className={isFaq ? "custom-nav text-dark" : "custom-nav"}
                    to={{ pathname: "https://blog.aikrut.id" }}
                    target="_parent"
                    disabled
                  >
                    {t("article")}
                  </Link>
                </NavItem>
                <NavItem className="has-subitem">
                  <Link className="btn button-landing px-2" to="/login">
                    {t("login")}
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
          <NavbarToggler className="" onClick={toggleNavbar}>
            <i className="fa fa-bars text-white" style={{ fontSize: "30px" }} />
          </NavbarToggler>
        </Container>
      </Navbar>
      <Modal
        isOpen={openDrawer}
        toggle={closeDrawer}
        className={"modal-drawer"}
      >
        <div className="drawer container">
          <div className="drawer-header pb-2">
            <NavbarBrand href="/" className="mr-auto">
              <img
                src={LogoBlue}
                id="aikrut-logo"
                className="navbar-logo"
                alt="widya-skilloka"
              />
            </NavbarBrand>
            <NavbarToggler
              className="close-drawer ml-auto"
              onClick={closeDrawer}
            />
          </div>
          <div className="d-flex flex-column text-center justify-content-center">
            <ul>
              <li
                className={`nav-item ${getNavItemClass("home")}`}
                onClick={() => {
                  closeDrawer();
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <Link to="/" className="nav-link m-0 py-2" disabled>
                  {t("Beranda")}
                </Link>
              </li>
              <li
                className={`nav-item ${getNavItemClass("assessmentInven")}`}
                onClick={() => {
                  closeDrawer();
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <Link
                  to="/assessment-inventory"
                  className="nav-link m-0 py-2"
                  disabled
                >
                  {t("Fitur")}
                </Link>
              </li>
              <li
                className={`nav-item ${getNavItemClass("pricing")}`}
                onClick={() => {
                  closeDrawer();
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <Link to="/pricing" className="nav-link m-0 py-2" disabled>
                  {t("Harga")}
                </Link>
              </li>
              <li
                className={`nav-item ${getNavItemClass("article")}`}
                onClick={() => closeDrawer()}
              >
                <Link
                  to={{ pathname: "https://blog.aikrut.id" }}
                  className="nav-link m-0 py-2"
                  target="_parent"
                  disabled
                >
                  {t("Artikel")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="btn button-landing px-2 py-2"
                  to="/login"
                  style={{ color: "#fff" }}
                >
                  Masuk
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default translate(NavbarComp);
