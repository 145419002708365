import React from "react";
import { t } from "react-switch-lang";
import {
  // Col,
  // Row,
  Card,
  CardBody,
  // CardImg,
  CardText,
  CardTitle,
} from "reactstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Testimoni() {
  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const data = [
    {
      id: 1,
      title: "Transcosmos",
      logo: "transcosmos.png",
      text: "Fitur-fitur Aikrut yang mudah digunakan dan dapat diakses secara real time sangat membantu kami.",
    },
    {
      id: 2,
      title: "UMG IdeaLab",
      logo: "umg.png",
      text: "Dibandingkan platform sejenis, Aikrut cukup canggih dan sangat membantu seleksi kandidat kerja.",
    },
    {
      id: 3,
      title: "Baramulti Group",
      logo: "baramulti-group.png",
      text: "Aikrut sangat membantu kami dalam mendapatkan talent terbaik.",
    },
  ];
  return (
    <>
      <section className="d-md-block d-none d-sm-none container-fluid testimoni-wrapper pt-3 pt-md-5">
        <h2 className="testimoni-heading text-center mb-5">
          {t("Testimonial")}
        </h2>
        <div className="testimoni-card-container container">
          <div
            className="d-flex flex-rows col-12 justify-content-center"
            style={{ position: "absolute" }}
          >
            {data.map((item) => (
              <Card
                key={item.id}
                className="col-4 testimoni-card px-0 pb-5 mr-3 text-center"
                style={{ border: "none", borderRadius: "15px" }}
              >
                <CardBody>
                  <div
                    className={
                      "d-md-flex justify-content-md-center align-items-md-end"
                    }
                    style={{ minHeight: "100px" }}
                  >
                    <img
                      src={"/partner/" + item.logo}
                      alt="testi img"
                      width={item.id !== 3 ? "100%" : "65%"}
                      // height="100%"
                      style={{ maxHeight: "100px", objectFit: "contain" }}
                    />
                  </div>

                  <CardTitle className="testi-card-title">
                    {item.title}
                  </CardTitle>
                  <CardText>{item.text}</CardText>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Mobile View */}
      <div className="testimoni-wrapper-mobile d-md-none d-block">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={1500}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={3000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {data.map((item) => (
            <div className="testimoni-card-mobile">
              <div
                className="pt-3 pb-1 mb-3 text-center"
                style={{
                  background: "#fff",
                  border: "none",
                  borderRadius: "15px",
                }}
              >
                <img
                  className="mb-3"
                  width="70%"
                  height="100px"
                  src={"/partner/" + item.logo}
                  alt="testimoni"
                  style={{ objectFit: "contain" }}
                />
                <p className="testimoni-title-mobile">{item.title}</p>
              </div>
              <div className="px-2">
                <p className="testimoni-desc-mobile">"{item.text}"</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Testimoni;
