import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { translate, t } from "react-switch-lang";
import axios from "axios";

function Partner() {
  const [visible, setVisible] = useState(true);
  const [firstCompany, setFirstCompany] = useState([]);
  const [secondCompany, setSecondCompany] = useState([]);

  const getDataLogoCompany = async () => {
    try {
      const res = await axios({
        method: "get",
        url: "list-company.json",
        baseURL: process.env.PUBLIC_URL,
      });

      const first = res?.data[0] ?? [];
      const second = res?.data[1] ?? [];

      const firstData = first.map((data) => ({
        name: data.name,
        image: data.image,
      }));

      const secondData = second.map((data) => ({
        name: data.name,
        image: data.image,
      }));

      setFirstCompany(firstData);
      setSecondCompany(secondData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDataLogoCompany();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisible(!visible);
    }, 5000);
    return () => clearInterval(interval);
  }, [visible]);

  return (
    <div className="container partner-page">
      <h3 className="partner-heading text-center mb-4 mb-md-5">
        {t("Klien Kami")}
      </h3>
      <div className="fade-div">
        <Row
          className={`d-flex justify-content-center partner-logo fade-div ${
            visible ? `hidden` : ``
          }`}
        >
          {firstCompany.map((item, idx) => (
            <Col
              key={idx}
              xs="4"
              md="2"
              className="d-flex justify-content-center align-items-center partner-col"
              style={{ minHeight: "100px" }}
            >
              <img
                src={`/partner/${item.image}`}
                style={{ objectFit: "contain", maxWidth: "100%" }}
                alt="partner"
              />
            </Col>
          ))}
        </Row>
        <Row
          className={`d-flex justify-content-center partner-logo fade-div ${
            !visible ? `hidden` : ``
          }`}
        >
          {secondCompany.map((item, idx) => (
            <Col
              key={idx}
              xs="4"
              md="2"
              className="d-flex justify-content-center align-items-center partner-col"
              style={{ minHeight: "100px" }}
            >
              <img
                src={`/partner/${item.image}`}
                style={{ objectFit: "contain", maxWidth: "100%" }}
                alt="partner"
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default translate(Partner);
