import React from "react";
import CountUp from "react-countup";
// import arutalaImg from "../../assets/img/newLandingPageV2/arutala.png";
// import msmbImg from "../../assets/img/newLandingPageV2/msmb.png";
// import autoconzImg from "../../assets/img/newLandingPageV2/autoconz.png";
// import griyatonImg from "../../assets/img/newLandingPageV2/griyaton.png";
// import idealabImg from "../../assets/img/newLandingPageV2/idealab.png";
// import { t } from "react-switch-lang";
// import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "reactstrap";

function TrustedPartner() {
  return (
    <section className="container-fluid trusted-partner-wrapper pb-4 pb-md-5">
      <div className="container d-flex flex-wrap trusted-partner pt-4 pt-md-5 justify-content-between">
        <Col md="3" className="text-md-center">
          <h1 style={{ fontSize: "60px", fontWeight: "600", color: "#3767B9" }}>
            <CountUp start={0} end={100} duration={2} />+
          </h1>
          <p>Perusahaan yang Telah Menggunakan</p>
        </Col>
        <Col md="1" />
        <Col md="3" className="text-md-center">
          <h1 style={{ fontSize: "60px", fontWeight: "600", color: "#3767B9" }}>
            <CountUp start={0} end={30} duration={2} />
            K+
          </h1>
          <p>User yang Telah Mengerjakan Asesmen</p>
        </Col>
        <Col md="1" />
        <Col md="3" className="text-md-center">
          <h1 style={{ fontSize: "60px", fontWeight: "600", color: "#3767B9" }}>
            <CountUp start={0} end={250} duration={2} />+
          </h1>
          <p>Lowongan Kerja yang Telah Terpublikasi</p>
        </Col>
        {/* <Row className="container col-md-6 col-12 trusted-content-left mb-5 mb-md-0">
         
        </Row> */}
      </div>
    </section>
  );
}

export default TrustedPartner;
