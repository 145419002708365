import React, { useEffect } from 'react';
import {
  Row,
  Col,
} from "reactstrap";
import { t } from "react-switch-lang";
import PageLayout from '../../Pages/Layout/PageLayout';
import ic_robot from "../../../assets/img/newLandingPageV2/robot.png"
import ic_ai from "../../../assets/img/newLandingPageV2/ai-anotation.png"
import ic_shio from "../../../assets/img/newLandingPageV2/shio.png"
import ic_sentiment from "../../../assets/img/newLandingPageV2/sentimenAnalisis.png"
import ic_realtime from "../../../assets/img/newLandingPageV2/realtime.png"
import ic_palmistry from "../../../assets/img/newLandingPageV2/palmistry.png"


function WhyAikrut() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <PageLayout>
      <div className="why-aikrut-wrapper mb-5">
        <section className="container-fluid hero-why-aikrut-wrapper">
          <div className="hero-why-aikrut container d-flex flex-rows justify-content-center align-items-center">
            <h3 className="hero-why-aikrut-heading mb-4 pt-3 pt-md-0 text-center text-white">
              {t("Perekrutan Karyawan menggunakan Artificial Intelligence dengan")} {" "}
              <span 
                  style={{ color: "#FF8A00" }}
                > Aikrut
                </span>
            </h3>
          </div>
        </section>
        <section className="container why-aikrut-content pt-5 mb-5">
          <div className="d-flex justify-content-center text-center">
          <div className="why-aikrut-heading">
            <h2 
              style={{ fontWeight: "700" }}
            >
            {t('Mengapa Aikrut?')}
            </h2>
            <br/>
            <p>{t('whyAikrutDesc')}</p>
          </div>
        </div>
        <Row className="mb-md-5">
          <Col md="4" sm="12" className="mb-3">
            <div className="text-md-left">
            <img src={ic_robot} className="mb-3" width="80" height="70" alt="robot"/>
            <h4 style={{fontWeight:"bolder"}}>{t('assistantDigitalRobotTitle')}</h4>
            <p>{t('assistantDigitalRobotDesc')}</p>
            </div>
          </Col>
          <Col md="4" sm="12" className="mb-3">
            <div className="text-md-left">
            <img src={ic_ai} className="mb-3" width="90" height="70" alt="robot"/>
            <h4 style={{fontWeight:"bolder"}}>{t('aiAnotationTitle')}</h4>
            <p>{t('aiAnotationDesc')}</p>
            </div>
          </Col>
          <Col md="4" sm="12" className="mb-3">
            <div className="text-md-left">
            <img src={ic_realtime} className="mb-3" width="70" height="70" alt="robot"/>
            <h4 style={{fontWeight:"bolder"}}>{t('realtimePsychologicalTitle')}</h4>
            <p>{t('realtimePsychologicalDesc')}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="12" className="mb-3">
            <div className="text-md-left">
            <img src={ic_palmistry} className="mb-3" width="90" height="70" alt="robot"/>
            <h4 style={{fontWeight:"bolder"}}>{t('physiognomyPalmistryTitle')}</h4>
            <p>{t('physiognomyPalmistryDesc')}</p>
            </div>
          </Col>
          <Col md="4" sm="12" className="mb-3">
            <div className="text-md-left">
            <img src={ic_shio} className="mb-3" width="70" height="70" alt="robot"/>
            <h4 style={{fontWeight:"bolder"}}>{t('shioBiometricTitle')}</h4>
            <p>{t('shioBiometricDesc')}</p>
            </div>
          </Col>
          <Col md="4" sm="12" className="mb-3">
            <div className="text-md-left">
            <img src={ic_sentiment} className="mb-3" width="90" height="70" alt="robot"/>
            <h4 style={{fontWeight:"bolder"}}>{t('sentimentAnalysistTitle')}</h4>
            <p>{t('sentimentAnalysistDesc')}</p>
            </div>
          </Col>
        </Row>
        </section>
      </div>
    </PageLayout>
  )
}

export default WhyAikrut
