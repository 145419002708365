import React from "react";
import { t } from "react-switch-lang";
// import { Link } from "react-router-dom";
import featureEntrepreneur from "../../assets/img/newLandingPageV2/feature-entrepreneur.png";
import ScrollAnimation from "react-animate-on-scroll";

function FeatureAikrut() {
  return (
    <section className="container feature-aikrut-3-wrapper">
      <div className="row feature-aikrut justify-content-between">
        <div className="col-12 col-md-6">
          <h3
            style={{
              color: "#FF8A00",
              fontWeight: "600",
              fontSize: "16px",
              marginBottom: "15px",
              letterSpacing: "3px",
            }}
          >
            {t("Fitur Aikrut")}
          </h3>
          <h1
            className="feature-aikrut-heading-1"
            style={{
              fontWeight: "600",
              color: "#18568B",
              marginBottom: "2rem",
            }}
          >
            {t(
              "Temukan kemampuan pola pikir bisnis kandidat dengan Tes Entrepreneur"
            )}
          </h1>
          <p className="mb-4" style={{ fontSize: "16px" }}>
            {t(
              "Tes entrepreneur memudahkan perusahaan untuk melihat pola pikir bisnis kandidat. Tanpa menyediakan tempat dan soal ujian, Perusahaan dengan mudah dapat menilai tanpa perlu bertemu langsung dengan kandidat."
            )}
          </p>
        </div>
        <div className="col-12 col-md-5 mb-5 text-center">
          <ScrollAnimation animateIn="fadeIn">
            <img
              className="feature-img-3"
              src={featureEntrepreneur}
              alt="aikrut feature"
            />
          </ScrollAnimation>
        </div>
      </div>
    </section>
  );
}

export default FeatureAikrut;
