import React from "react";
import CountUp from "react-countup";
// import { isMobile } from "react-device-detect";
import { t } from "react-switch-lang";

function Hero() {
  return (
    <section className="container-fluid hero-wrapper pb-3 " id="home">
      <div className="container d-flex flex-rows align-items-center hero">
        <div
          className="hero-content-left col-12 col-md-6"
          style={{ position: "relative" }}
        >
          <h1 className="hero-heading mb-4 pt-3 pt-md-0 text-white">
            <span style={{ color: "#FF8A00" }}>#</span>
            {t("RekrutLebihCepat")}
          </h1>
          <p className="hero-text text-white mb-md-5 mb-4">
            {t(
              "Online profiling platform berbasis analisa data dan teknologi, "
            )}
            <i>Artificial Intelligence</i>
            {t(
              " membantu Anda merekrut kandidat terbaik secara efektif dan efisien."
            )}
          </p>
          <a
            href="/register"
            className="btn btn-lg mb-md-5 mb-3"
            style={{
              backgroundColor: "#FF8A00",
              color: "#fff",
              borderRadius: "50px",
            }}
          >
            {t("Coba Gratis")}
          </a>
          <div className="text-white">
            <h1
              style={{
                fontSize: "64px",
                fontWeight: "600",
              }}
            >
              <CountUp start={0} end={70} duration={1} />
              {"%"}
            </h1>
            <p>{t("Waktu Rekrutmen yang Lebih Efektif")}</p>
          </div>
        </div>
        <div className="hero-content-right col-12 col-md-6">
          <img
            className="hero-img"
            width="100%"
            src={require("../../assets/img/newLandingPageV2/hero-img.png")}
            alt="hero img"
          />
          {/* {isMobile ? (
            <img
              width="100%"
              src={require("../../assets/img/newLandingPageV2/hero-img.png")}
              alt="hero-items"
            />
          ) : (
            <img
              width="120%"
              src={require("../../assets/img/newLandingPageV2/hero-img.png")}
              alt="hero-items"
              style={{
                marginTop: "-16%",
              }}
            />
          )} */}
          {/* <img className="hero-icon hero-icon-1" src={iconHero1} alt="hero icon" />
          <p className="hero-element hero-element-1">
            Mudah Digunakan
          </p>
          <p className="hero-element hero-element-2">
            Efektif dan Akurat
          </p>
          <img className="hero-icon hero-icon-2" src={iconHero2} alt="hero icon" />
          <p className="hero-element hero-element-3">
            Artificial Intelligence
          </p>
          <img className="hero-icon hero-icon-3" src={iconHero3} alt="hero icon" /> */}
        </div>
      </div>
    </section>
  );
}

export default Hero;
