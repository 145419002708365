import React from "react";
import NavbarComp from "../../NewLandingPageV2/Navbar";
import Footer from "../../NewLandingPageV2/Footer";
import PropTypes from "prop-types";

// import ComingSoon from "../../LandingPage/ComingSoon";

function PageLayout(props) {
  return (
    // <ComingSoon>
    <div className="landing-page">
      {props.navbarComp === false ? null : <NavbarComp />}
      {/* <NavbarComp /> */}
      {props.children}
      {props.footer === false ? null : <Footer />}
    </div>
    // </ComingSoon>
  );
}
PageLayout.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.bool,
  navbarComp: PropTypes.bool,
};
PageLayout.defaultTypes = {
  footer: true,
  navbarComp: true,
};
export default PageLayout;
