import React, { useEffect, useState } from "react";
import {
  Spinner,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import request from "../../../utils/request";
import { t } from "react-switch-lang";
import TokenIcon from "./TokenIcon";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopUpToken from "./Token/TopUpToken";
import { useBalance } from "../../../hooks/useBalance";
import { useMediaQuery } from "react-responsive";
import { useAuthUser, useUserPrivileges } from "../../../store";
import ModalPrivilegeForbidden from "../../../components/ModalPrivilegeForbidden";
import PurchasedToken from "./Token/PurchasedToken";

function RecruitmentToken({ props, visible }) {
  const [modalToken, setModalToken] = useState(false);
  const [priceList, setPriceList] = useState(null);
  const isSmallSize = useMediaQuery({ query: "(max-width: 768px)" });
  const user = useAuthUser();
  const { can } = useUserPrivileges();
  const [forbidden, setForbidden] = useState(false);
  const [url, setUrl] = useState(null);

  const hasUrl = (data) => {
    setUrl(data);
  };

  const toggle = () => {
    if (can("canManagementTopUp")) {
      setModalToken(!modalToken);
    } else {
      setForbidden(true);
    }
  };

  const { loading, data } = useBalance();
  const myBalance = data?.balance ?? 0;

  useEffect(() => {
    request.get("v1/master/token_prices").then((res) => {
      setPriceList(res.data.data);
    });
  }, []);

  return (
    <>
      {forbidden && (
        <ModalPrivilegeForbidden
          isOpen={true}
          isClose={() => setForbidden(false)}
          forbidden="canManagementTopUp"
        />
      )}
      {modalToken && (
        <TopUpToken
          data={data}
          hasUrl={hasUrl}
          priceList={priceList}
          isOpen={true}
          isClose={() => setModalToken(false)}
        />
      )}
      {url && (
        <PurchasedToken url={url} isOpen={true} isClose={() => setUrl(null)} />
      )}
      <div className="d-flex flex-row nav-token">
        {visible ? (
          <div
            className={`col-xs-12 ${
              props.personnel.company.paid === "pre" || props.personnel.company.paid === "education"? `col-md-4` : `col-md-6`
            } ${!isSmallSize ? `tour-history` : ""}`}
          >
            <TokenIcon />
          </div>
        ) : null}
        {props.personnel.company.paid === "pre" || props.personnel.company.paid === "education"? (
          <div
            className={`col-xs-12 ${
              props.personnel.company.paid === "pre" || props.personnel.company.paid === "education"? `col-md-4` : `col-md-6`
            } ${!isSmallSize ? `tour-topup` : ""}`}
          >
            <div
              className={`my-auto nav-item`}
              onClick={toggle}
              style={{ color: "#305574", cursor: "pointer" }}
            >
              <i className="fa fa-plus-square mr-1" /> <b>Top Up</b>
            </div>
            {/* <TopUpToken token={data} /> */}
          </div>
        ) : null}
        <div
          className={`col-xs-12 ${
            props.personnel.company.paid === "pre" || props.personnel.company.paid === "education"? `col-md-4` : `col-md-6`
          } ${!isSmallSize ? `tour-token` : ""}`}
        >
          <div
            className={`my-auto nav-item ${
              props.personnel.company.paid === "pre" || props.personnel.company.paid === "education"                ? `button-token`
                : `button-token-post`
            }`}
            disabled={true}
          >
            {loading ? (
              <Spinner size="sm" color="primary" className="my-auto" />
            ) : (
              <>
                <FontAwesomeIcon icon="coins" className="mr-1" />{" "}
                <b>{myBalance}</b>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Mobile */}
      {user.guidance.header ? (
        <UncontrolledDropdown
          nav
          direction="down"
          className="nav-token-mobile mr-1"
        >
          <DropdownToggle nav>
            <div className="d-flex flex-row">
              {/* <div className="col-11"> */}
              <div
                className={`nav-item button-token ${
                  isSmallSize ? `tour-token` : ""
                }`}
              >
                {loading ? (
                  <Spinner size="sm" color="primary" className="mx-auto" />
                ) : (
                  <div className="p-1">
                    <FontAwesomeIcon icon="coins" className="mr-1" />{" "}
                    <b>{myBalance}</b>
                  </div>
                )}
              </div>
              <div className="my-auto ml-2 font-weight-bold">
                <i
                  className="fa fa-2x fa-angle-down"
                  style={{ fontWeight: "bold" }}
                />
              </div>
              {/* </div> */}
            </div>
          </DropdownToggle>
          <DropdownMenu right>
            {props.personnel.company.paid === "pre" || props.personnel.company.paid === "education"? (
              <DropdownItem
                onClick={toggle}
                className={isSmallSize ? "tour-topup" : ""}
              >
                <i className="fa fa-plus-square mr-1" /> Top Up
              </DropdownItem>
            ) : null}
            <Link to="/tokenhistory" style={{ color: "#23282c" }}>
              <DropdownItem className={isSmallSize ? "tour-history" : ""}>
                <i className={`fa fa-history mr-1`} aria-hidden="true" />{" "}
                {t("riwayat")}
              </DropdownItem>
            </Link>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <UncontrolledDropdown
          isOpen={true}
          nav
          direction="down"
          className="nav-token-mobile mr-1"
        >
          <DropdownToggle nav>
            <div className="d-flex flex-row">
              {/* <div className="col-11"> */}
              <div
                className={`nav-item button-token ${
                  isSmallSize ? `tour-token` : ""
                }`}
              >
                {loading ? (
                  <Spinner size="sm" color="primary" className="mx-auto" />
                ) : (
                  <div className="p-1">
                    <FontAwesomeIcon icon="coins" className="mr-1" />{" "}
                    <b>{myBalance}</b>
                  </div>
                )}
              </div>
              <div className="my-auto ml-2 font-weight-bold">
                <i
                  className="fa fa-2x fa-angle-down"
                  style={{ fontWeight: "bold" }}
                />
              </div>
              {/* </div> */}
            </div>
          </DropdownToggle>
          <DropdownMenu right>
            {props.personnel.company.paid === "pre" || props.personnel.company.paid === "education"? (
              <DropdownItem
                disabled={true}
                className={isSmallSize ? "tour-topup" : ""}
              >
                <i className="fa fa-plus-square mr-1" /> Top Up
              </DropdownItem>
            ) : null}
            <DropdownItem
              disabled={true}
              className={isSmallSize ? "tour-history" : ""}
            >
              <i className={`fa fa-history mr-1`} aria-hidden="true" />{" "}
              {t("riwayat")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
}

export default RecruitmentToken;
