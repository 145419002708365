import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
// import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import { t } from "react-switch-lang";
import featureAgility from "../../assets/img/newLandingPageV2/feature-agility.png";

function FeatureAikrut2() {
  return (
    <section
      className="feature-aikrut-2-wrapper d-flex align-items-center"
      style={{ height: "100%" }}
    >
      <div className="container">
        <div className="row feature-aikrut-2">
          <div className="col-12 col-md-5 mb-5 mb-md-0 text-center">
            <ScrollAnimation animateIn="fadeIn">
              <img
                src={featureAgility}
                className="feature-img-2"
                alt="aikrut feature"
              />
            </ScrollAnimation>
          </div>
          <Col md="1" />
          <div className="col-12 col-md-5">
            <h3
              style={{
                color: "#FF8A00",
                fontWeight: "600",
                fontSize: "16px",
                marginBottom: "15px",
                letterSpacing: "3px",
              }}
            >
              {t("Fitur Aikrut")}
            </h3>
            <h1
              className="feature-aikrut-heading-1 text-white"
              style={{
                fontWeight: "600",
                marginBottom: "2rem",
              }}
            >
              {t("Ukur kemampuan Agility kandidat perusahaan")}
            </h1>
            <p className="mb-4 text-white" style={{ fontSize: "16px" }}>
              {t(
                "Dalam Tes Agility, perusahaan dapat mengetahui tingkat adaptasi kandidat dalam menghadapi krisis dan ketidakpastian."
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureAikrut2;
