import React from "react";
import LogoWhite from "../../assets/assets_ari/logo_white.png";
import { translate, t } from "react-switch-lang";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

function Footer() {
  // const date = new Date();
  // let currYear = date.getFullYear();

  return (
    <div className="footer-landing">
      <div className="container">
        <Row>
          <Col md="6">
            <img
              src={LogoWhite}
              alt="widya_skilloka"
              className="logo_white img-fluid mb-4"
            />
            <p className="aboutus">
              Aikrut by Widya adalah platform yang mempermudah dan
              mengoptimalkan secara singkat proses rekrutmen perusahaan Anda.
              Anda dapat mengakses CV online, hasil asesmen psikotes dan hasil
              wawancara online kandidat pelamar yang sudah otomatis terintegrasi
              dengan kecerdasan buatan.
            </p>
          </Col>
          <Col md="2" className="d-flex flex-column information">
            <h5 className="mb-3">
              <strong>Informasi</strong>
            </h5>
            <Link
              className="info-aboutus mb-3"
              to="/"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              Tentang Kami
            </Link>
            <Link
              className="info-faq"
              to="/faq"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              FAQ
            </Link>
          </Col>
          <Col md="4" className="contactus">
            <h5>
              <strong>{t("PT Widya Indonesia Sejahtera")} : </strong>
            </h5>
            <div className="d-flex align-items-start">
              <i
                className="fa fa-map-marker text-white mr-3 mt-1"
                style={{ fontSize: "25px" }}
              />
              <p className="mb-1">
                <a
                  href="https://goo.gl/maps/8QbdjaJv1dYHsJDB6"
                  className="text-white"
                >
                  Gg. Pusponyidro No.12B, Muja Muju
                  <br />
                  Kec. Umbulharjo, Kota Yogyakarta <br />
                  Daerah Istimewa Yogyakarta 55165
                </a>
              </p>
            </div>
            <div className="d-flex align-items-start">
              <i
                className="fa fa-whatsapp text-white mr-3 mt-1"
                style={{ fontSize: "25px" }}
              />
              <a
                href="https://wa.me/6282134881108"
                className="mb-1 text-white"
                style={{ flex: "1" }}
              >
                +62 821 3488 1108
              </a>
            </div>
            <div className="d-flex align-items-center">
              <i
                className="fa fa-phone text-white mr-3 mt-1"
                style={{ fontSize: "25px" }}
              />
              <p className="mb-1" style={{ flex: "1" }}>
                02744542237
              </p>
            </div>
            <div className="d-flex align-items-start">
              <i
                className="fa fa-envelope text-white mr-3 mt-1"
                style={{ fontSize: "25px" }}
              />
              <p className="mb-1">
                <a
                  href="mailto:bizdev@widyaskilloka.com"
                  style={{ flex: "1", color: "#dce6ef" }}
                >
                  bizdev@widyaskilloka.com
                </a>
              </p>
            </div>
          </Col>
        </Row>
        <p className="copyright text-center mt-4">
          © 2021 PT Widya Indonesia Sejahtera. All Rights Reserved
        </p>
      </div>
    </div>
  );
}

export default translate(Footer);
