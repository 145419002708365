import React, { useMemo, memo, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { useTokenNotification } from "../../../hooks/useTokenNotification";
import { Link } from "react-router-dom";
import { translate, t } from "react-switch-lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import langUtils from "../../../utils/language/index";
import moment from "moment";
const TokenIcon = memo(() => {
  const { data, loading, error } = useTokenNotification([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const notifications = useMemo(() => data.slice(0, 4), [data]);

  return (
    <Dropdown
      nav
      direction="down"
      isOpen={dropdownOpen}
      toggle={toggle}
      className="tour-history-token"
    >
      <DropdownToggle
        nav
        style={{ minWidth: 100, color: "#305574" }}
        className="no-hover"
      >
        <i className={`fa fa-history mr-1`} /> <b>{t("riwayat")}</b>
      </DropdownToggle>
      <DropdownMenu right style={{ zIndex: 999 }}>
        {error ? (
          <DropdownItem>
            <i className="fa fa-warning text-danger"></i>{" "}
            {t("terjadikesalahan")}
          </DropdownItem>
        ) : loading ? (
          <DropdownItem>Loading...</DropdownItem>
        ) : data.length === 0 ? (
          <DropdownItem>{t("tokenHistoryEmpty")}</DropdownItem>
        ) : (
          <>
            <DropdownItem
              header
              className="d-flex align-items-center border-bottom-0"
            >
              <strong>{t("notification")}</strong>
            </DropdownItem>
            {notifications.map((notification) => (
              <DropdownItem
                key={notification.id}
                className="text-capitalize d-flex justify-content-between"
              >
                <div
                  role="menuitem"
                  tabIndex="0"
                  className={`dropdown-item d-flex align-items-center border-bottom-0`}
                >
                  {notification.type === "usage" ? (
                    <>
                      <div className="mr-4 font-xl dropdown-token">
                        <FontAwesomeIcon
                          icon="coins"
                          className="mx-auto"
                          style={{ color: "#ffd601" }}
                        />
                      </div>
                      <div className="flex-fill small">
                        <div className="d-flex">
                          <div>
                            <span className="font-weight-bold mr-3">
                              {notification.nominal} {t("tokenIsUsedFor")}&nbsp;
                              {notification?.usage?.tokenType === "spm"
                                ? t("spm")
                                : notification?.usage?.tokenType ===
                                  "fingerprint"
                                ? t("fingerprint")
                                : notification?.usage?.tokenType ===
                                  "businessInsight"
                                ? t("businessInsight")
                                : notification?.usage?.tokenType}{" "}
                              {t("tokenIsUsedForFeature")}
                            </span>

                            <br />
                            <span className="ml-auto text-muted">
                              <i
                                className="icon-clock mx-0 w-auto text-muted"
                                style={{ fontSize: 10 }}
                              ></i>{" "}
                              {moment(notification.date)
                                .locale(langUtils.getLanguage())
                                .format("DD MMMM YYYY LT")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {notification.causer.id !== 0 ? (
                        <>
                          <div className="mr-4 font-xl dropdown-token">
                            <FontAwesomeIcon
                              icon="plus-circle"
                              className="mx-auto"
                              style={{ color: "#47e0af" }}
                            />
                          </div>
                          <div className="flex-fill small">
                            <div className="d-flex">
                              <div>
                                <span className="font-weight-bold mr-3">
                                  {notification.nominal} {t("tokenAdded")}
                                </span>
                                <br />
                                <span className="ml-auto text-muted">
                                  <i
                                    className="icon-clock mx-0 w-auto text-muted"
                                    style={{ fontSize: 10 }}
                                  ></i>{" "}
                                  {moment(notification.date)
                                    .locale(langUtils.getLanguage())
                                    .format("DD MMMM YYYY LT")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : notification.nominal === 0 ? (
                        <>
                          <div className="mr-4 font-xl dropdown-token">
                            <FontAwesomeIcon
                              icon="calendar-times"
                              className="mx-auto"
                              style={{ color: "#e0474d" }}
                            />
                          </div>
                          <div className="flex-fill small">
                            <div className="d-flex">
                              <div>
                                <span className="font-weight-bold mr-3">
                                  {t("tokenExpired")}
                                </span>
                                <br />
                                <span className="ml-auto text-muted">
                                  <i
                                    className="icon-clock mx-0 w-auto text-muted"
                                    style={{ fontSize: 10 }}
                                  ></i>{" "}
                                  {moment(notification.date)
                                    .locale(langUtils.getLanguage())
                                    .format("DD MMMM YYYY LT")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mr-4 font-xl dropdown-token">
                            <FontAwesomeIcon
                              icon="plus-circle"
                              className="mx-auto"
                              style={{ color: "#47e0af" }}
                            />
                          </div>
                          <div className="flex-fill small">
                            <div className="d-flex">
                              <div>
                                <span className="font-weight-bold mr-3">
                                  {t("getFreeToken")} {notification.nominal}{" "}
                                  token{" "}
                                </span>
                                <br />
                                <span className="ml-auto text-muted">
                                  <i
                                    className="icon-clock mx-0 w-auto text-muted"
                                    style={{ fontSize: 10 }}
                                  ></i>{" "}
                                  {moment(notification.date)
                                    .locale(langUtils.getLanguage())
                                    .format("DD MMMM YYYY LT")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </DropdownItem>
            ))}
          </>
        )}
        <Link
          to="/tokenhistory"
          onClick={() => toggle()}
          className="text-center dropdown-header"
        >
          {t("seeAll")}
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
});

export default translate(TokenIcon);
