import React from "react";
import { useEffect } from "react";
import PageLayout from "../Pages/Layout/PageLayout";
import FeatureAikrut from "./FeatureAikrut";
import FeatureAikrut2 from "./FeatureAikrut2";
import FeatureAikrut3 from "./FeatureAikrut3";
import FeatureAikrut4 from "./FeatureAikrut4";
import Hero from "./Hero";
import TrustedPartner from "./TrustedPartner";
import TryForFreeComp from "./TryForFreeComp";
import WhyAikrut from "./WhyAikrut";
import Testimoni from "./Testimoni";
import Partner from "./OurPartner/Partner";
import WhatsappButton from "./WhatsappButton";
function LandingPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <PageLayout>
      <Hero />
      <TrustedPartner />
      <Partner />

      <WhyAikrut />
      <FeatureAikrut />
      <FeatureAikrut2 />
      <FeatureAikrut3 />
      <FeatureAikrut4 />
      <Testimoni />
      <TryForFreeComp />

      <WhatsappButton />
    </PageLayout>
  );
}

export default LandingPage;
