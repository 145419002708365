import React, { useEffect } from "react";
import { t } from "react-switch-lang";
import { isMobile } from "react-device-detect";
// import recruitmentImg1 from "../../../assets/img/newLandingPageV2/recruitment-1.png";
import recruitmentImg2 from "../../../assets/img/newLandingPageV2/recruitment-2.png";
import recruitmentImg3 from "../../../assets/img/newLandingPageV2/recruitment-3.png";
// import recruitmentMobileImg1 from "../../../assets/img/newLandingPageV2/recruitment-mobile-1.png";
import recruitmentMobileImg2 from "../../../assets/img/newLandingPageV2/recruitment-mobile-2.png";
import recruitmentMobileImg3 from "../../../assets/img/newLandingPageV2/recruitment-mobile-3.png";
import PageLayout from "../../Pages/Layout/PageLayout";
import WhatsappButton from "../WhatsappButton";

function Recruitment() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <PageLayout>
      <div className="recruitment-wrapper">
        <section className="container-fluid hero-why-aikrut-wrapper">
          <div className="hero-why-aikrut container d-flex flex-column justify-content-center align-items-center">
            <h3 className="hero-why-aikrut-heading mb-4 pt-3 pt-md-0 text-center text-white">
              {t("Buat Proses Rekrutmen Menjadi Lebih Ringkas")}
            </h3>
            <p className="text-white" style={{ fontSize: "18px" }}>
              {t(
                "Setelah menggunakan Aikrut, langkah-langkah rekrutmen menjadi lebih singkat"
              )}
            </p>
          </div>
        </section>
        <section className="container recruitment-content pt-5 mb-3 mb-md-5">
          <div
            className="recruitment-content-2"
            style={{ marginBottom: "5rem" }}
          >
            <h3
              className="recruitment-heading text-center mb-4 mb-md-5"
              style={{
                color: "#FF8A00",
                fontWeight: "600",
              }}
            >
              {t("Aikrut akan melaksanakan ini untuk Anda")}
            </h3>
            {isMobile ? (
              <div>
                <img
                  width="100%"
                  src={recruitmentMobileImg2}
                  alt="recruitment img"
                />
              </div>
            ) : (
              <div>
                <img width="100%" src={recruitmentImg2} alt="recruitment img" />
              </div>
            )}
          </div>
          <div
            className="recruitment-content-3"
            style={{ marginBottom: "5rem" }}
          >
            <h3
              className="recruitment-heading text-center mb-4 mb-md-5"
              style={{
                color: "#FF8A00",
                fontWeight: "600",
              }}
            >
              {t("Setelah Menggunakan Aikrut")}
            </h3>
            {isMobile ? (
              <div>
                <img
                  width="100%"
                  src={recruitmentMobileImg3}
                  alt="recruitment img"
                />
              </div>
            ) : (
              <div>
                <img width="100%" src={recruitmentImg3} alt="recruitment img" />
              </div>
            )}
          </div>
          <div>
            <p
              className="recruitment-text"
              style={{
                fontSize: "16px",
                lineHeight: "32px",
                letterSpacing: "1px",
              }}
            >
              Dengan <strong>Aikrut,</strong> proses perekrutan karyawan di
              perusahaan Anda akan jauh lebih mudah dan cepat. Kami akan
              membantu Anda dalam menyeleksi kandidat, dari penjadwalan,
              interview kandidat, dan tes psikologi. Tidak perlu khawatir, kami
              menggunakan teknologi AI yang canggih untuk menyortir karyawan
              yang akan cocok untuk ada di perusahaan Anda. Selain itu, kami
              juga menyediakan jasa tes psikologi, yang bisa Anda dapatkan
              hasilnya dengan cepat dan akurat. Hasil tes ini dilakukan oleh
              mesin, menjadikan seleksi di perusahaan Anda menjadi minim bias.
              Bersama Aikrut, <strong>#RekrutLebihCepat!</strong>
            </p>
          </div>
        </section>
      </div>
      <WhatsappButton />
    </PageLayout>
  );
}

export default Recruitment;
