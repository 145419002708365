import React from 'react';
import {
  Row,
  Col,
} from "reactstrap";

function TryForFreeComp() {
  return (
    <section className="tryforfree container mb-5">
        <Row className="tryforfree-card align-items-center text-white mx-0 py-3">
            <Col md="7" sm="12">
              <h3 style={{fontWeight:"bold", fontSize:"24px"}}>Dapatkan hasil asesmen lebih cepat, <br/> lengkap dan jelas.</h3>
            </Col>
            <Col md="5" sm="12" className="text-md-right text-sm-center">
              {/* <Link 
                to="/register"
                className="btn-tryFree p-2 px-4"
                style={{
                  backgroundColor:'white',
                  color:"#3767B9",
                  fontSize: "24px",
                  fontWeight: 'bold',
                  textDecoration:"none",
                  borderRadius:"22px"
                }}
                >Mulai Uji Coba Gratis
                </Link> */}
                <a 
                  href="/register"
                  className="btn-tryFree p-2 px-md-4 px-md-1"
                >
                  Mulai Uji Coba Gratis
                </a>
            </Col>
        </Row>
    </section>
  )
}

export default TryForFreeComp
