import React, { useState, useEffect } from "react";
import { t } from "react-switch-lang";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import PageLayout from "../../Pages/Layout/PageLayout";
import leadershipImg from "../../../assets/img/newLandingPageV2/leadership-assessment.png";
import personalityImg from "../../../assets/img/newLandingPageV2/personality-assessment.png";
import palmistryImg from "../../../assets/img/newLandingPageV2/palmistry-assessment.png";
import mbtiImg from "../../../assets/img/newLandingPageV2/mbti-assessment.png";
import workStyleImg from "../../../assets/img/newLandingPageV2/work-style-assessment.png";
import cognitiveImg from "../../../assets/img/newLandingPageV2/cognitive-assessment.png";
import eyeAccesingImg from "../../../assets/img/newLandingPageV2/eye-accesing-assessment.png";
import learningAgilityImg from "../../../assets/img/newLandingPageV2/learningAgility-assessment.png";
import entrepeneurImg from "../../../assets/img/newLandingPageV2/entrepeneur-assessment.png";
import gestureImg from "../../../assets/img/newLandingPageV2/gesture-assessment.png";
import fisiognomiImg from "../../../assets/img/newLandingPageV2/fisiognomi-assessment.png";
import biometricImg from "../../../assets/img/newLandingPageV2/biometric-assessment.png";
import TryForFreeComp from "../TryForFreeComp";
import WhatsappButton from "../WhatsappButton";

function AssessmentInventory() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <PageLayout>
      <AssessmentInventoryWrapper />
      <WhatsappButton />
    </PageLayout>
  );
}

function AssessmentInventoryWrapper() {
  const [isActive, setIsActive] = useState(0);

  const data = [
    {
      id: 1,
      title: "Eye Accesing Cues",
      text: "merupakan pengukuran melalui pola gerakan mata yang dilakukan di bawah alam sadar individu saat mengakses informasi tertentu dalam pikirannya menggunakan teknik Neuro-Linguistic Programming (NLP). Pola gerakan mata ini seringkali menjadi data tambahan sebagai alat observasi individu dalam lingkup rekrutmen.",
      img: eyeAccesingImg,
    },
    {
      id: 2,
      title: "Gesture Assessment",
      text: "adalah pengukuran karakteristik melalui pola gestur yang dilakukan secara tidak sadar saat menyampaikan suatu informasi. Analisa ini seringkali menjadi menjadi data tambahan sebagai alat observasi dalam rekrutmen.",
      img: gestureImg,
    },
    {
      id: 3,
      title: "Learning Agility",
      text: "adalah alat ukur yang disusun berdasarkan teori yang dikemukakan oleh DeRue, Ashford, dan Myers (2012). Diketahui bahwa terdapat dua proses yang mendasari agility individu, yaitu proses kognitif (cognitive) dan perilaku (behaviour). Proses kognitif terdiri atas cognitive simulations, counterfactual thinking, dan pattern recognition. Sementara itu pada proses perilaku terdiri atas feedback seeking, experimentation, dan reflection. Alat ukur ini bertujuan untuk melihat kemampuan individu dalam mempelajari hal baru secara cepat dan fleksibel.",
      img: learningAgilityImg,
    },
    {
      id: 4,
      title: "Entrepeneur Test",
      text: "adalah alat ukur yang dikembangkan berdasarkan teori milik Lumpkin, dkk (1996). Dalam jurnalnya, Lumpkin, dkk mengenalkan lima aspek yang dapat mempengaruhi orientasi kewirausahaan, yaitu autonomy, innovativeness, risk taking, proactiveness, competitive aggresiveness. Alat ukur ini disusun untuk mengetahui bagaimana kecenderungan individu dalam memanfaatkan peluang usaha.",
      img: entrepeneurImg,
    },
    {
      id: 5,
      title: "Aptitude Test",
      text: "adalah psikotes yang dirancang untuk mengukur preferensi dasar murni psikologis seseorang dalam melihat dunia dan membuat keputusan. MBTI dikembangkan oleh Isabel Briggs Myers pada sejak 1940. Psikotes ini dirancang untuk mengukur kecerdasan individu, bakat, dan tipe kepribadian seseorang.",
      img: mbtiImg,
    },

    {
      id: 6,
      title: "Work Style Test",
      text: "adalah pengukuran potensi berisi data mengenai dinamika kepribadian yang dipengaruhi oleh situasi kerja di sekitarnya. Data-data tersebut terdiri atas kebiasaan pola pikir dan tingkah laku, seperti tujuan, sebab, dan dorongan individu untuk melakukan sesuatu. Hasil tes ini akan memberikan gambaran kepribadian secara keseluruhan yang utuh dan tidak terpisah-pisah.",
      img: workStyleImg,
    },
    {
      id: 7,
      title: "Personality Test",
      text: "adalah pengukuran kompetensi berisi data mengenai kepribadian perilaku individu dalam situasi pekerjaan. Hasil tentang kepribadian individu sendiri berisi motivasi yang mendorong suatu perilaku individu dan gaya komunikasi individu dalam lingkungan kerja.",
      img: personalityImg,
    },

    {
      id: 8,
      title: "Leadership Test",
      text: "adalah pengukuran kompetensi berisi data mengenai kemampuan dan model kepemimpinan individu dalam perusahaan. Hasil dari tes ini berupa 4 model kepemimpinan yang mengukur seberapa jauh pemimpin mampu mengembangkan tugas agar lebih sistematis serta mengukur kemampuan manajerial di tim dalam bekerja sama memecahkan masalah.",
      img: leadershipImg,
    },
    {
      id: 9,
      title: "Cognitive Ability Test",
      text: (
        <>
          Cognitive Ability Test adalah pengukuran potensi berisi data mengenai
          kapasitas intelegensi secara umum. Data tes ini mendasari kemampuan
          individu dalam melakukan hal-hal tertentu. Hasil yang dimunculkan
          dalam tes ini berupa 5 level kecerdasan, yaitu{" "}
          <i>
            Intellectually Superior, Definitely above the Average in
            Intellectual Capacity, Intellectually Average, Definitely Below the
            Average in Intellectual Capacity, Intellectually Defective.
          </i>
        </>
      ),
      img: cognitiveImg,
    },

    {
      id: 10,
      title: "Fisiognomi",
      text: "Aikrut membantu Anda dalam membaca kepribadian seseorang melalui karakter wajah. Menggunakan teknologi Artificial Intelligence, ekspresi kandidat akan terinterpretasi selama interview. Melalui hasil yang komprehensif, Anda bisa mengetahui karakteristik kandidat secara keseluruhan. Dengan begitu, Anda bisa memahami bagaimana kandidat tersebut akan berperilaku dalam bekerja di perusahaan Anda.",
      img: fisiognomiImg,
    },
    {
      id: 11,
      title: "Palmistry",
      text: "adalah pengukuran karakteristik kepribadian dari hasil analisa garis tangan. Melalui analisa ini, perusahaan dapat mengenal lebih dekat mengenai karakter individu dan menjadikan ini sebagai nilai tambahan.",
      img: palmistryImg,
    },
    {
      id: 12,
      title: "Biometric Test",
      text: "adalah pengukuran potensi berisi data mengenai kemampuan mengembangkan diri dan beradaptasi. Data tes ini diambil dari sidik jari, yang hasilnya mencakup kemampuan interpersonal, kemampuan intrapersonal, kemampuan manajerial, pengambilan keputusan, kemampuan kinestetik, kemampuan komunikasi, dan kemampuan adaptif.",
      img: biometricImg,
    },
  ];

  const [currentMenu, setCurrentMenu] = useState(data[0]);

  const changeMenu = (num) => {
    setCurrentMenu(data[num]);
    setIsActive(num);
  };
  return (
    <div className="assessment-inventory-wrapper mb-5">
      <section className="container-fluid hero-assessment-wrapper">
        <div className="hero-assessment container text-center d-flex flex-column justify-content-center align-items-center">
          <h3 className="hero-assessment-heading mb-4 pt-3 pt-md-0 text-center text-white">
            {t("Berbagai Fitur Asesmen di")}{" "}
            <span style={{ color: "#FF8A00" }}>Aikrut</span>
          </h3>
          <p className="text-white" style={{ fontSize: "18px" }}>
            Fitur Aikrut yang dapat membantu perekrutan menjadi Efektif,
            Efisien, Praktis, dan Minim Bias
          </p>
        </div>
      </section>
      <section className="assessment-inventory-content container pt-5">
        <div className="d-md-block text-center mb-5">
          <h2
            className="features-heading"
            style={{
              fontWeight: "700",
              fontSize: "30px",
              color: "#18568B",
            }}
          >
            {t("Fitur Asesmen")}
          </h2>
          <br />
          <p>
            {t(
              "Dapatkan berbagai jenis asesmen yang sesuai untuk kandidat perusahaan"
            )}
          </p>
        </div>
        <Row>
          <Col md="3" className="side-menu">
            <h3
              className="side-menu-title"
              style={{
                color: "#18568B",
                fontWeight: "700",
                fontSize: "24px",
              }}
            >
              {t("Fitur")}
            </h3>
            <div className="side-menu-list text-left">
              <ul style={{ padding: "0" }}>
                {data.map((menu, idx) => (
                  <li>
                    <button
                      type="button"
                      className={
                        isActive === idx
                          ? "btn-sideMenu active"
                          : "btn-sideMenu"
                      }
                      onClick={() => changeMenu(menu.id - 1)}
                    >
                      {menu.title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col>
            <Card className="card-assessment-inventory shadow mb-5">
              <CardTitle>
                <h3
                  className="ml-3"
                  style={{
                    fontWeight: "bold",
                    color: "#18568B",
                  }}
                >
                  {currentMenu.title}
                </h3>
              </CardTitle>
              <CardBody className="text-left">
                {currentMenu.id !== 10 ? (
                  <>
                    <i>{currentMenu.title}</i> {currentMenu.text}{" "}
                  </>
                ) : (
                  <>{currentMenu.text} </>
                )}

                <br />
                {currentMenu["img"] !== null ? (
                  <div className="d-flex justify-content-center mt-3 mt-md-5">
                    <Card
                      className="card-img-assessment shadow p-3"
                      style={{
                        border: "none",
                        borderRadius: "10px",
                      }}
                    >
                      <img
                        width="100%"
                        src={currentMenu.img}
                        alt="inventori asesmen img"
                      />
                    </Card>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </section>
      <TryForFreeComp />
    </div>
  );
}

export default AssessmentInventory;
