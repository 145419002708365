import React, { useEffect, useState } from "react";
import {
  Spinner,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
} from "reactstrap";
import request from "../../../utils/request";
// import { t } from "react-switch-lang";
// import TokenIcon from "./TokenIcon";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopUpToken from "./Token/TopUpToken";
import { useUserBalance } from "../../../hooks/useUserBalance";
import { useMediaQuery } from "react-responsive";
import { useAuthUser } from "../../../store";
import ModalPrivilegeForbidden from "../../../components/ModalPrivilegeForbidden";
import PurchasedToken from "./Token/PurchasedToken";

function RecruitmentToken({ props, visible }) {
  const [modalToken, setModalToken] = useState(false);
  const [priceList, setPriceList] = useState(null);
  const isSmallSize = useMediaQuery({ query: "(max-width: 768px)" });
  const user = useAuthUser();
  // const { can } = useUserPrivileges();
  const [forbidden, setForbidden] = useState(false);
  const [url, setUrl] = useState(null);

  const hasUrl = (data) => {
    setUrl(data);
  };

  // const toggle = () => {
  //   if (can("canManagementTopUp")) {
  //     setModalToken(!modalToken);
  //   } else {
  //     setForbidden(true);
  //   }
  // };

  const { loading, data } = useUserBalance();
  const myBalance = data?.balance ?? 0;

  useEffect(() => {
    request.get("v1/master/token_prices").then((res) => {
      setPriceList(res.data.data);
    });
  }, []);

  return (
    <>
      {forbidden && (
        <ModalPrivilegeForbidden
          isOpen={true}
          isClose={() => setForbidden(false)}
          forbidden="canManagementTopUp"
        />
      )}
      {modalToken && (
        <TopUpToken
          data={data}
          hasUrl={hasUrl}
          priceList={priceList}
          isOpen={true}
          isClose={() => setModalToken(false)}
        />
      )}
      {url && (
        <PurchasedToken url={url} isOpen={true} isClose={() => setUrl(null)} />
      )}
      <div className={`d-flex flex-row nav-token ${!isSmallSize ? `tour-token` : ``}`}>
        <div
          className={`col-xs-12 ${
            props.personnel.company.paid === "pre" ? `col-md-4` : `col-md-6`
          }`}
        >
          <div
            className={`my-auto nav-item ${
              props.personnel.company.paid === "pre"
                ? `button-token`
                : `button-token-post`
            }`}
            disabled={true}
            style={{ minWidth: "70px" }}
          >
            {loading ? (
              <Spinner size="sm" color="primary" className="my-auto" />
            ) : (
              <>
                <FontAwesomeIcon icon="users" className="mr-1" />{" "}
                <b>{myBalance}</b>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Mobile */}
      {user.guidance.header ? (
        <div className="d-flex flex-row nav-token-mobile mr-1">
          {/* <div className="col-11"> */}
          <div
            className={`nav-item button-token ${
              isSmallSize ? `tour-token` : ""
            }`}
          >
            {loading ? (
              <Spinner size="sm" color="primary" className="mx-auto" />
            ) : (
              <div className="p-1">
                <FontAwesomeIcon icon="users" className="mr-1" />{" "}
                <b>{myBalance}</b>
              </div>
            )}
          </div>

          {/* </div> */}
        </div>
      ) : (
        <div className="d-flex flex-row nav-token-mobile mr-1">
          {/* <div className="col-11"> */}
          <div
            className={`nav-item button-token ${
              isSmallSize ? `tour-token` : ""
            }`}
          >
            {loading ? (
              <Spinner size="sm" color="primary" className="mx-auto" />
            ) : (
              <div className="p-1">
                <FontAwesomeIcon icon="users" className="mr-1" />{" "}
                <b>{myBalance}</b>
              </div>
            )}
          </div>

          {/* </div> */}
        </div>
      )}
    </>
  );
}

export default RecruitmentToken;
