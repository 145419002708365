import React, { useEffect } from 'react';
import PageLayout from '../../Pages/Layout/PageLayout';
import { translate, t} from "react-switch-lang";
import TryForFreeComp from '../TryForFreeComp';

function BenefitToClient() {
  const windowOnScroll = () => {
    if (window.scrollY > 0) {
      if (
        !document.getElementsByTagName("nav")[0].classList.contains("shadow-sm")
      ) {
        document.getElementsByTagName("nav")[0].classList.add("shadow-sm");
      }
    } else {
      if (
        document.getElementsByTagName("nav")[0].classList.contains("shadow-sm")
      ) {
        document.getElementsByTagName("nav")[0].classList.remove("shadow-sm");
      }
    }
  }
  
  useEffect(() => {
    window.addEventListener("scroll", windowOnScroll);
    
    return() => {
      window.removeEventListener("scroll", windowOnScroll);
  
    }
  })
  return (
    <PageLayout>
      <section className="benefit-to-client container mb-md-5" style={{paddingTop:"10px"}}>
        <div 
          className="benefit-header d-md-block text-center mb-md-5"
         
          >
          <h2 style={{fontWeight:'bold'}}>{t('benefitTitle')}</h2><br/>
          <p>{t('benefitTitleDesc')}</p>
        </div>
        <div className="benefit-content-list">
          <div className="benefit-content-wrapper">
            <h4 className="benefit-content-title">{t('benefitContentTitle1')}</h4>
            <p className="benefit-content-desc">{t('benefitContentDesc1')}</p>
          </div>
          <div className="benefit-content-wrapper">
            <h4 className="benefit-content-title">{t('benefitContentTitle2')}</h4>
            <p className="benefit-content-desc">{t('benefitContentDesc2')}</p>
          </div>
          <div className="benefit-content-wrapper">
            <h4 className="benefit-content-title">{t('benefitContentTitle3')}</h4>
            <p className="benefit-content-desc">{t('benefitContentDesc3')}</p>
          </div>
          <div className="benefit-content-wrapper">
            <h4 className="benefit-content-title">{t('benefitContentTitle4')}</h4>
            <p className="benefit-content-desc">{t('benefitContentDesc4')}</p>
          </div>
          <div className="benefit-content-wrapper">
            <h4 className="benefit-content-title">{t('benefitContentTitle5')}</h4>
            <p className="benefit-content-desc">{t('benefitContentDesc5')}</p>
          </div>
          <div className="benefit-content-wrapper">
            <h4 className="benefit-content-title">{t('benefitContentTitle6')}</h4>
            <p className="benefit-content-desc">{t('benefitContentDesc6')}</p>
          </div>
          <div className="benefit-content-wrapper">
            <h4 className="benefit-content-title">{t('benefitContentTitle7')}</h4>
            <p className="benefit-content-desc">{t('benefitContentDesc7')}</p>
          </div>
          <div className="benefit-content-wrapper">
            <h4 className="benefit-content-title">{t('benefitContentTitle8')}</h4>
            <p className="benefit-content-desc">{t('benefitContentDesc8')}</p>
          </div>
          <span>

          </span>
        </div>

      </section>

      <TryForFreeComp/>
    </PageLayout>
  )
}

export default translate(BenefitToClient)
