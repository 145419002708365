import React from "react";
import { t } from "react-switch-lang";
import { Link } from "react-router-dom";
import featureAssessment from "../../assets/img/newLandingPageV2/feature-assessment.png";
import ScrollAnimation from "react-animate-on-scroll";

function FeatureAikrut() {
  return (
    <section className="container feature-aikrut-4-wrapper">
      <div className="row feature-aikrut-4 justify-content-between mb-4">
        <div className="col-12 col-md-5 text-center">
          <ScrollAnimation animateIn="fadeIn">
            <img
              width="100%"
              className="feature-img-4 mb-5"
              src={featureAssessment}
              alt="aikrut feature"
            />
          </ScrollAnimation>
        </div>
        <div className="col-12 col-md-6">
          <h3
            style={{
              color: "#FF8A00",
              fontWeight: "600",
              fontSize: "16px",
              marginBottom: "15px",
              letterSpacing: "3px",
            }}
          >
            {t("Fitur Aikrut")}
          </h3>
          <h1
            className="feature-aikrut-heading-1"
            style={{
              fontWeight: "600",
              color: "#18568B",
              marginBottom: "2rem",
            }}
          >
            {t("Temukan kandidat terbaik dengan berbagai asesmen lainnya")}
          </h1>
          <p className="mb-4" style={{ fontSize: "16px" }}>
            {t(
              "Penilaian yang valid secara ilmiah dan minim bias untuk membantu perusahaan memilih kandidat dengan kualitas terbaik."
            )}
          </p>
        </div>
      </div>
      <div className="text-center">
        <Link
          to="assessment-inventory"
          className="btn button-landing text-white"
          style={{
            width: "220px",
            fontSize: "18px",
          }}
        >
          {t("Pelajari Lebih Lanjut")}
        </Link>
      </div>
    </section>
  );
}

export default FeatureAikrut;
