import React from "react";
import { t } from "react-switch-lang";
// import { Link } from "react-router-dom";
import featureAI from "../../assets/img/newLandingPageV2/feature-ai.png";
import ScrollAnimation from "react-animate-on-scroll";

function FeatureAikrut() {
  return (
    <section className="container feature-aikrut-wrapper">
      <div className="row feature-aikrut justify-content-between">
        <div className="col-12 col-md-6">
          <h3
            style={{
              color: "#FF8A00",
              fontWeight: "600",
              fontSize: "16px",
              marginBottom: "15px",
              letterSpacing: "3px",
            }}
          >
            {t("Fitur Aikrut")}
          </h3>
          <h1
            className="feature-aikrut-heading-1"
            style={{
              fontWeight: "600",
              color: "#18568B",
              marginBottom: "2rem",
            }}
          >
            {t("Rekrut dengan AI untuk mempermudah proses penyeleksian")}
          </h1>
          <p className="mb-4" style={{ fontSize: "16px" }}>
            {t(
              "Video interview berbasis AI membuat perekrutan lebih cepat dan terukur. Tanpa bertemu langsung, Anda dapat mengevaluasi interview dan menganalisa raut wajah kandidat."
            )}
          </p>
        </div>
        <div className="col-12 col-md-6 text-center">
          <ScrollAnimation animateIn="fadeIn">
            <img
              width="80%"
              className="feature-img-1"
              src={featureAI}
              alt="aikrut feature"
            />
          </ScrollAnimation>
        </div>
      </div>
    </section>
  );
}

export default FeatureAikrut;
