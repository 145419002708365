import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { translate, t } from "react-switch-lang";
import { Link } from "react-router-dom";

function CustomOffering() {
  const customText = [
    "Token yang dapat diatur sesuai kebutuhan",
    "Publikasi lowongan tak terbatas",
    "Akses AI interview dan asesmen kandidat",
    "Gratis biaya maintenance",
    "Tanpa biaya server",
    "Tanpa biaya bulanan",
    "Pertanyaan yang dapat disesuaikan",
    "API Integration",
    "1 tahun waktu akses",
  ];

  return (
    <div className="custom-offer container pricing">
      <div className="customOffering-heading text-center">
        <h4 style={{ fontWeight: 700, fontSize: "28px" }}>
          {t("Kami juga memiliki penawaran paket lainnya")}
        </h4>
      </div>
      <Row>
        <Col md="6" lg="7">
          <h4 style={{ color: "#FF8A00", fontSize: "16px", fontWeight: "600" }}>
            {t("Paket Lainnya")}
          </h4>
          <h4 style={{ color: "#18568B", fontWeight: "600", fontSize: "30px" }}>
            {t(
              "Customize Package yang Dapat Disesuaikan dengan Kebutuhan Perusahaan"
            )}
          </h4>
          <p>
            Melalui Customize Package, Aikrut menyediakan layanan yang secara
            spesifik dapat mengakomodasi kebutuhan rekrutmen perusahaan. Tanpa
            perlu khawatir, perusahaan dapat mengatur semuanya, dari jumlah
            token, hingga pertanyaan-pertanyaan yang akan diajukan kepada
            kandidat.
          </p>
        </Col>
        <Col md="6" lg="5">
          <Card className="card-price">
            <CardHeader className="border-bottom-0 bg-transparent pt-4 pb-2">
              <h5>{t("Customize Package")}</h5>
            </CardHeader>
            <CardBody>
              {customText.map((item, idx) => (
                <Row key={idx}>
                  <Col xs="1" className="mb-2">
                    <i
                      className="fa fa-check-circle"
                      style={{
                        color: "#3767B9",
                        fontSize: "18px",
                      }}
                    />
                  </Col>
                  <Col xs="10" className="mb-2">
                    {item}
                  </Col>
                </Row>
              ))}
            </CardBody>
            <CardFooter className="border-top-0 bg-transparent text-center pb-3">
              <Link to="/register">
                <Button className={`px-2 button-price`}>
                  {t("contactUs")}
                </Button>
              </Link>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default translate(CustomOffering);
