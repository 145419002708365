import React from "react";
import { t } from "react-switch-lang";

function TryForFreeComp({bg}) {
  return (
    <section className="container mb-5 w-100">
      <div className={`d-flex flex-column align-items-center ${bg ? `` : `try-card`} pt-5`}>
        <div className={`text-center ${bg ? `` : `text-white`}`}>
          <h3>{t("Dapatkan informasi tentang Aikrut")}</h3>
          <h3>{t("serta akun uji coba gratis.")}</h3>
          <br />
          <a
            href="/register"
            className="btn button-landing p-3 mb-5"
            style={{
              width: "200px",
            }}
          >
            {t("Coba Sekarang")}
          </a>
        </div>
      </div>
    </section>
  );
}

export default TryForFreeComp;
