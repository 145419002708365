export const cardContent = [
  {
    name:'Free Package',
    token:25,
    isBest:false,
    expired:9,
    date:'hari'
  },
  {
    name:'Business Package',
    token:250,
    isBest:false,
    expired:3,
    date:'bulan'
  },
  {
    name:'Pro Package',
    token:500,
    isBest:true,
    expired:6,
    date:'bulan'
  },
  {
    name:'Premium Package',
    token:1000,
    isBest:false,
    expired:1,
    date:'tahun'
  }
]