import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { t } from "react-switch-lang";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import efektif from "../../assets/img/newLandingPageV2/efektif.png";
import efisien from "../../assets/img/newLandingPageV2/efisien.png";
import praktis from "../../assets/img/newLandingPageV2/praktis.png";
import minimBias from "../../assets/img/newLandingPageV2/minimBias.png";

function WhyAikrut() {
  const data = [
    {
      id: 1,
      title: "Efektif",
      desc: "Fitur tepat guna dengan hasil valid yang sesuai dengan kebutuhan perusahaan",
      img: efektif,
    },
    {
      id: 2,
      title: "Efisien",
      desc: "Perekrutan yang awalnya rumit dan memakan waktu menjadi cepat dan ringkas.",
      img: efisien,
    },
    {
      id: 3,
      title: "Praktis",
      desc: "Tidak berbelit-belit, mudah diaplikasikan bahkan oleh pemula",
      img: praktis,
    },
    {
      id: 4,
      title: "Minim Bias",
      desc: "Sistem AI yang kompeten dapat membuat perekrutan menjadi akurat",
      img: minimBias,
    },
  ];

  return (
    <section className="whyaikrut-wrapper container">
      <h2 className="whyaikrut-heading">{t("Mengapa Aikrut?")}</h2>
      <div className="col-12">
        <Row className=" mb-3 justify-content-between">
          {data.map((item) => (
            <Col
              md="5"
              className="d-flex flex-rows align-items-start mb-3 mb-md-5"
            >
              <div className="mr-4">
                <ScrollAnimation animateIn="fadeIn">
                  <img
                    className="whyaikrut-img"
                    width={118}
                    height={130}
                    src={item.img}
                    alt="landingpage asset"
                    style={{ objectFit: "contain" }}
                  />
                </ScrollAnimation>
              </div>
              <div className="mt-3">
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
        <div className="text-center">
          <Link
            to="/aikrut-recruitment"
            className="btn button-landing text-white"
            style={{
              width: "220px",
              fontSize: "18px",
            }}
          >
            {t("Pelajari Lebih Lanjut")}
          </Link>
        </div>
      </div>
    </section>
  );
}

export default WhyAikrut;
