import React from 'react';
import {Modal, ModalHeader, ModalBody, Button, ModalFooter} from 'reactstrap'
import {translate, t} from "react-switch-lang";
function PurchasedToken({url, isOpen, isClose}){

return(
    <Modal isOpen={isOpen} className="token-master">
        <ModalHeader className="border-bottom-0">
            {t('tokenPurchaseProcess')}
        </ModalHeader>
        <ModalBody>
            <div className="d-flex justify-content-between align-middle mb-3">
                <div className="mt-2">
                    {t('tokenPurchaseDone')}
                </div>
            </div>
        </ModalBody>
        <ModalFooter className="border-top-0">
            <a href={url} target="_blank" rel="noopener noreferrer">
                <Button onClick={isClose} color="netis-danger">
                    {t('ok')}
                </Button>
            </a>
        </ModalFooter>
    </Modal>
    )
}

export default translate(PurchasedToken)