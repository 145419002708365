import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  CardHeader,
} from "reactstrap";
import Zoom from "react-reveal/Zoom";
import { t } from "react-switch-lang";
import PageLayout from "../../Pages/Layout/PageLayout";
import { cardContent } from "./CardContent";
import CustomOffering from "./CustomOffering";
import FAQWithoutPageLayout from "../FAQ/FAQWithoutPageLayout";
import WhatsappButton from "../WhatsappButton";

function Pricing() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <PageLayout>
      <section className="pricing-wrapper mb-5">
        <div className="container-fluid hero-pricing-wrapper mb-5">
          <div className="hero-pricing container text-center text-white d-flex flex-column justify-content-center align-items-center">
            <h3 className="hero-pricing-heading mb-4 pt-3 pt-md-0 text-white">
              {t("Paket Berlangganan Aikrut")}
            </h3>
            <p className="text-white" style={{ fontSize: "18px" }}>
              {t(
                " Temukan berbagai paket yang sesuai dengan kebutuhan perusahaan"
              )}
            </p>
          </div>
        </div>
        <div className="container-fluid pricing-content">
          <div className="text-center" style={{ marginBottom: "4rem" }}>
            <h4
              style={{ fontWeight: "600", fontSize: "32px", color: "#3767B9" }}
            >
              {t("Solusi Rekrutmen Terbaik untuk Perusahaan")}
            </h4>
            <br />
            <p style={{ lineHeight: "24px", fontSize: "18px" }}>
              {t(
                "Menggunakan teknologi Robot AI, jadikan pengalaman digitalisasi rekrutmen menjadi lebih mudah dan efektif."
              )}
            </p>
          </div>
          <div className="pricing text-center">
            <Row className="price-row">
              {cardContent.map((item, idx) => (
                <Col md="6" lg="3" key={idx}>
                  <PriceCard
                    title={item.name}
                    token={item.token}
                    isBest={item.isBest}
                    expired={item.expired}
                    date={item.date}
                  />
                </Col>
              ))}
            </Row>
          </div>
          <CustomOffering />
          <FAQWithoutPageLayout />
        </div>
      </section>

      <WhatsappButton />
    </PageLayout>
  );
}

function PriceCard({ title, token, isBest, expired, date }) {
  const exp = expired + " " + date + " " + t("Waktu Akses");
  const checkText = [
    t("Publikasi lowongan tak terbatas"),
    t("Akses AI interview dan asesmen kandidat"),
    t("Gratis biaya maintenance"),
    t("Tanpa biaya server"),
    t("Tanpa biaya bulanan"),
    exp,
  ];
  return (
    <Zoom>
      <div>
        {isBest && (
          <div className="best-offer mb-0">
            <h6>{t("Penawaran Terbaik")}</h6>
          </div>
        )}
        <Card className={isBest ? "card-price-offer" : "card-price"}>
          <CardHeader className="border-bottom-0 text-center pt-3 bg-transparent">
            <h5>{title}</h5>
            <h6 style={{ color: "#FF8A00" }}>{token}&nbsp;Token</h6>
          </CardHeader>
          <CardBody className="p-2 p-xl-4">
            <div className="card-price-body text-left mt-1">
              {checkText.map((item, idx) => (
                <Row key={idx}>
                  <Col xs="1" className="mb-2">
                    <i
                      className="fa fa-check-circle"
                      style={{ color: "#3767B9", fontSize: "18px" }}
                    />
                  </Col>
                  <Col xs="10" className="mb-2">
                    {item}
                  </Col>
                </Row>
              ))}
            </div>
          </CardBody>
          <CardFooter
            className="bg-white border-top-0 pt-1 card-pricing-footer"
            style={{ paddingBottom: "3rem" }}
          >
            <Link to="/register">
              <Button
                className={`px-2 ${
                  isBest ? `button-price` : `button-price-outline`
                }`}
              >
                {t("contactUs")}
              </Button>
            </Link>
          </CardFooter>
        </Card>
      </div>
    </Zoom>
  );
}

export default Pricing;
