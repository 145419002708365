import React, { Suspense, useEffect, useState } from "react";
import * as router from "react-router-dom";
import { Row, Button } from "reactstrap";

import {
  // AppAside,
  // AppFooter,
  // AppHeader,
  // AppSidebar,
  // AppSidebarFooter,
  // AppSidebarForm,
  // AppSidebarHeader,
  // AppSidebarMinimizer,
  // AppBreadcrumb2 as AppBreadcrumb,
  // AppSidebarNav2 as AppSidebarNav,
  AppHeader,
} from "@coreui/react";
import { translate, t } from "react-switch-lang";
import DefaultHeader from "./DefaultHeader";
import { Container } from "reactstrap";
import adminMenus from "./admin-menu";
import adminMenusSub from "./admin-menu-sub";
// import userMenus from "./user-menu";
// import LoadingAnimation from "../../views/Menu/Personnel/component/atom/LoadingAnimation";
import AuthRoute from "../../components/AuthRoute";
import { connect, useDispatch } from "react-redux";
import Spinner from "reactstrap/lib/Spinner";
import Countdown from "../../views/Menu/Recruitment/Countdown/Countdown";
import Tour from "reactour";
import request from "../../utils/request";
import { getMe } from "../../actions/auth";

function DefaultLayout(props) {
  const menu = props.panelMenu;
  const { Switch, Redirect } = router;

  const [isTour, setIsTour] = useState(null);
  const [firstTour, setFirstTour] = useState(false);

  const accentColor = "#1d5a8e";
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.user?.guidance) {
      if (!props.user.guidance.layout) {
        window.scrollTo(0, 0);
        setFirstTour(true);
      }
    }
  }, [props.user]);

  useEffect(() => {
    if (props?.user?.guidance?.layout) {
      if (!props.user.guidance.header) {
        if(props.user.personnel?.company?.paid === 'sub'){
          setIsTour(2)
        }
        else {
          setIsTour(1)
        }
        // setIsTour(true);
      }
    }
  }, [props.user]);

  const disableGuidanceLayout = () => {
    setFirstTour(false);
    if(props.user.personnel?.company?.paid === 'sub'){
      setIsTour(2)
    }
    else {
      setIsTour(1)
    }
    request.put("auth/guidance", { guidance: "layout" }).then(() => {
      dispatch(getMe());
    });
  };
  const disableGuidanceHeader = () => {
    setIsTour(null);
    request.put("auth/guidance", { guidance: "header" }).then(() => {
      dispatch(getMe());
    });
  };
  const disableGuidanceAll = () => {
    setFirstTour(false);
    setIsTour(null);
    request.put("auth/guidance", { guidance: "all" }).then(() => {
      dispatch(getMe());
    });
  };

  const firstStep = [
    {
      content: () => (
        <div>
          <h4
            className="title-upgrade text-center"
            style={{ color: "#93aad6" }}
          >
            {t("welcomeAikrut")}
          </h4>
          <p className="text-justify">
            {t("welcomeAikrutPoint1")}
            {/* Panduan singkat ini hanya bisa dilihat satu kali, jika Anda memilih untuk melewatkannya,
            anda tidak akan bisa melihat petunjuk ini lagi. */}
            {t("welcomeAikrutPoint1sub1")} <b>{t("welcomeAikrutPoint1sub2")}</b>{" "}
            {t("welcomeAikrutPoint1sub3")}
          </p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0 div-cancel-outline">
                <Button
                  className="mt-2 button-outline-red"
                  type="submit"
                  // color="netis-danger"
                  onClick={() => {
                    disableGuidanceAll();
                  }}
                >
                  {t("btnNo")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    // disableBody();
                    disableGuidanceLayout();
                  }}
                >
                  {t("btnYes")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];

  const steps = [
    {
      selector: ".tour-token",
      content: ({ goTo, inDOM }) => (
        <div>
          <h5
            className="title-upgrade text-center"
            style={{ color: "#93aad6" }}
          >
            {t("welcomeTokenGuide")}
          </h5>
          <p>
            {t('welcomeTokenPeserta')}
            {/* {props.user.personnel.company.paid === "pre"
              ? t("welcomeTokenGuidePoint1")
              : t("welcomeTokenGuidePoint1post")} */}
          </p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-right p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() =>
                    (props.user.personnel.company.paid === "pre" || props.user.personnel.company.paid === "education")
                      ? goTo(1)
                      : goTo(2)
                  }
                >
                  {t("btnContinue")} <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".tour-topup",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("welcomeTokenGuidePoint2")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(0)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("btnPrevious")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(2)}
                >
                  Selanjutnya <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".tour-history",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("welcomeTokenGuidePoint3")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() =>
                    props.user.personnel.company.paid === "pre"
                      ? goTo(1)
                      : goTo(0)
                  }
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("btnPrevious")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-success"
                  onClick={() => disableGuidanceHeader()}
                >
                  {t("btnOk")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];

  const subSteps = [
    {
      selector: ".tour-token",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>
            {t('welcomeTokenSub')}
          </p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-right p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => disableGuidanceHeader()}
                >
                  {t("btnContinue")} <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    }
  ]

  const generateRoutes = (menu) => {
    return adminMenus().map((props, idx) => (
      <AuthRoute
        key={idx}
        path={props.url}
        exact={!!props.exact}
        component={props.component}
        {...props}
      />
    ));
  };

  const generateRoutesSub = (menu) => {
    return adminMenusSub().map((props, idx) => (
      <AuthRoute
        key={idx}
        path={props.url}
        exact={!!props.exact}
        component={props.component}
        {...props}
      />
    ));
  };

  // eslint-disable-next-line
  const generateMenus = (menu) => {
    const { privileges: userPrivileges = [] } = props.user;
    const checkPrivileges = (routeObj) => {
      if (routeObj.privileges) {
        return routeObj.privileges.every((p) => userPrivileges.includes(p));
      }

      if (routeObj.oneOfPrivileges) {
        return routeObj.oneOfPrivileges.some((p) => userPrivileges.includes(p));
      }

      return true;
    };

    return adminMenus()
      .filter((routeObj) => !!routeObj.menu)
      .filter(checkPrivileges)
      .map(({ menu, url }) => {
        return { ...menu, url };
      });
  };
  return (
    <div className="app">
      <Tour
        showCloseButton={false}
        steps={firstStep}
        accentColor={accentColor}
        showNumber={false}
        isOpen={firstTour}
        closeWithMask={false}
        disableDotsNavigation={true}
        rounded={5}
        showNavigation={false}
        showButtons={false}
        disableFocusLock={false}
        className="p-4"
        onRequestClose={() => {
          disableGuidanceLayout();
        }}
      />
      <Tour
        steps={steps}
        accentColor={accentColor}
        showButtons={false}
        rounded={5}
        isOpen={isTour === 1}
        showNavigation={false}
        showNumber={false}
        closeWithMask={false}
        disableInteraction={true}
        disableFocusLock={false}
        onBeforeClose={() => window.scroll({ top: 0, behavior: "smooth" })}
        onRequestClose={() => {
          disableGuidanceHeader();
        }}
      />
      <Tour
        steps={subSteps}
        accentColor={accentColor}
        showButtons={false}
        rounded={5}
        isOpen={isTour === 2}
        showNavigation={false}
        showNumber={false}
        closeWithMask={false}
        disableInteraction={true}
        disableFocusLock={false}
        onBeforeClose={() => window.scroll({ top: 0, behavior: "smooth" })}
        onRequestClose={() => {
          disableGuidanceHeader();
        }}
      />
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <div className="app-body">
        <main className="main" style={{ backgroundColor: "#F5F6FA" }}>
          <Container fluid>
            {props.user.personnel?.company?.paid !== "sub" && <Countdown />}
            <Suspense
              fallback={
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    background: "rgba(255,255,255, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner style={{ width: 48, height: 48 }} />
                </div>
              }
            >
              <Switch>
                <Redirect exact from="/home" to="/recruitment" />
                {props.user.personnel?.company?.paid === "sub" ? generateRoutesSub(menu) : generateRoutes(menu)}
              </Switch>
            </Suspense>
          </Container>
        </main>
      </div>
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  user: reduxState.user,
  panelMenu: reduxState.menu,
});

export default connect(mapStateToProps)(translate(DefaultLayout));
