import React, { useState } from "react";
import { Link } from "react-router-dom";
import { t, translate } from "react-switch-lang";
export default translate(function WhatsAppButton() {
  const [isOpenWA, setIsOpenWA] = useState(false);
  const openWa = () => {
    setIsOpenWA(!isOpenWA);
  };
  return (
    <div className="fab-whatsapp">
      {isOpenWA ? (
        <div id="box-wa" className="box-wa">
          <div
            className="closed-btn"
            role="button"
            tabIndex="0"
            onClick={openWa}
          ></div>
          <div className="header-box-wa">
            <div className="logo-wa">
              <div className="logo-wa-inner">
                <div className="img-logo-wa"></div>
              </div>
            </div>
            <h3 style={{ color: "#fff" }} className="ml-3">
              Aikrut
            </h3>
            <br />
            <br />
          </div>
          <div className="content-box-wa">
            <div className="content-flex-wa">
              <div className="wa-dots">
                <div className="wa-dots-inner">
                  <div className="wa-dots-one"></div>
                  <div className="wa-dots-two"></div>
                  <div className="wa-dots-three"></div>
                </div>
              </div>
              <div className="content-message-wa">
                <div className="wa-author">Aikrut</div>
                <div className="wa-text">
                  <b>{t("hiYou")} </b>
                  <br />
                  <p>{t("canWeHelpYou")}</p>
                </div>
              </div>
            </div>

            <Link
              to={{ pathname: "https://wa.me/6282134881108" }}
              target="_blank"
              rel="noreferrer noopener"
              className="wa-mobile"
            >
              <div role="button" title="WhatsApp" className="gotoChat">
                <span className="text-wa-goto">{t("startChat")}</span>
              </div>
            </Link>
            <Link
              to={{
                pathname: "https://web.whatsapp.com/send?phone=6282134881108",
              }}
              target="_blank"
              rel="noreferrer noopener"
              className="wa-web"
            >
              <div role="button" title="WhatsApp" className="gotoChat">
                <span className="text-wa-goto">{t("startChat")}</span>
              </div>
            </Link>
          </div>
        </div>
      ) : null}
      <img
        className="mr-1"
        src={require("../../assets/img/whatsapp.svg")}
        alt="contact whatsapp"
        onClick={openWa}
      />
      <br />
      <small className="text-netis-primary bg-white shadow-sm py-1 px-2 rounded-pill fab-whatsapp-caption">
        Hubungi Kami
      </small>
    </div>
  );
});
